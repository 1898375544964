import {
    DeleteOutlined,
    MenuOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Space,
    Upload,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import _ from "lodash";
import React, { useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { CopyIcon } from "src/components";
import { Player } from "@lottiefiles/react-lottie-player";
import { getPresignedURL } from "src/components/form/RenderRecord";
import axios from "axios";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: "pointer",
        }}
    />
));

const SortableContainerCustom: any = SortableContainer(({ children }: any) => {
    return <div>{children}</div>;
});

const SortableItemCustom: any = SortableElement((props: any) => {
    const { children, sid } = props;
    return (
        <div style={{ zIndex: 1000 }} key={`${sid}`}>
            {children}
        </div>
    );
});

const StickerPool = ({ disableWrite, schema, onSave, sticker_pool }: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [curConfig, setCurConfig] = useState(
        sticker_pool?.map((v: any) => ({ ...v, uniqueId: _.uniqueId() })),
    );
    const [tempConfig, setTempConfig] = useState("");

    const handleSubmit = async () => {
        try {
            let v = curConfig.map((v: { uniqueId: any }) => {
                delete v.uniqueId;
                return v;
            });
            await onSave(v);
            message.success("Successfully Updated!");
            setIsModalOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        let tmpBlock = _.cloneDeep(curConfig);
        tmpBlock = arrayMoveImmutable(tmpBlock, oldIndex, newIndex).filter(
            (el) => !!el,
        );
        setCurConfig(tmpBlock);
    };

    return (
        <div>
            <Form.Item label="Sticker Pool">
                <Button
                    type="primary"
                    onClick={() => setIsModalOpen(true)}
                    size="small"
                >
                    Sticker Pool
                </Button>
            </Form.Item>
            <Modal
                title={`Sticker Pool`}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                width={720}
                maskClosable={false}
                style={{ top: 10 }}
                destroyOnClose
            >
                <Row
                    style={{
                        maxHeight: "70vh",
                        overflowY: "auto",
                        overflowX: "hidden",
                        scrollbarWidth: "thin",
                    }}
                >
                    <Col span={24}>
                        {schema && !disableWrite && (
                            <Button
                                ghost
                                type="primary"
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(curConfig);
                                    tmpBlock.push({
                                        ..._.cloneDeep(schema),
                                        uniqueId: _.uniqueId(),
                                    });
                                    setCurConfig(tmpBlock);
                                }}
                            >
                                Add New Sticker
                            </Button>
                        )}
                        <Divider />
                    </Col>
                    <Col span={24}>
                        <Form.Item label={"Paste Sticker Config here"}>
                            <Input.TextArea
                                disabled={disableWrite}
                                value={tempConfig}
                                placeholder="Paste Config here"
                                onChange={(e) => setTempConfig(e.target.value)}
                            />
                            <Space>
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempConfig);
                                            if (
                                                !Array.isArray(parsedJson) ||
                                                parsedJson?.some(
                                                    (v) => !v?.json,
                                                )
                                            ) {
                                                message.error("Error in JSON!");
                                                return;
                                            }
                                            setCurConfig(
                                                parsedJson.map((v) => ({
                                                    ...v,
                                                    uniqueId: _.uniqueId(),
                                                })),
                                            );
                                            setTempConfig("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e)
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Replace
                                </Button>
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson =
                                                JSON.parse(tempConfig);
                                            if (
                                                !Array.isArray(parsedJson) ||
                                                parsedJson?.some(
                                                    (v) => !v?.json,
                                                )
                                            ) {
                                                message.error("Error in JSON!");
                                                return;
                                            }
                                            setCurConfig([
                                                ...curConfig,
                                                ...parsedJson.map((v) => ({
                                                    ...v,
                                                    uniqueId: _.uniqueId(),
                                                })),
                                            ]);
                                            setTempConfig("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            // captureException(e);
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Append
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col style={{ width: "100%" }}>
                        <SortableContainerCustom
                            onSortEnd={onSortEnd}
                            useDragHandle
                        >
                            {curConfig?.map(
                                (sticker: any, audioIdx: number) => {
                                    const audioName = sticker?.x;

                                    if (!sticker["type"]) {
                                        let tmpBlock = _.cloneDeep(curConfig);
                                        _.set(
                                            tmpBlock,
                                            [audioIdx, "type"],
                                            "LOTTIE",
                                        );
                                        setCurConfig(tmpBlock);
                                    }

                                    return (
                                        <SortableItemCustom
                                            disabled={disableWrite}
                                            index={audioIdx}
                                            sid={sticker?.uniqueId}
                                            key={sticker?.uniqueId}
                                        >
                                            <div
                                                key={sticker?.uniqueId}
                                                style={{
                                                    border: "1px dotted black",
                                                    padding: "8px",
                                                    margin: "8px 0",
                                                    width: "100%",
                                                }}
                                            >
                                                <Space
                                                    style={{
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    <DragHandle />
                                                    <h3>Name - {audioName}</h3>
                                                    <Button
                                                        disabled={disableWrite}
                                                        icon={
                                                            <DeleteOutlined />
                                                        }
                                                        type="primary"
                                                        shape="circle"
                                                        danger
                                                        size="small"
                                                        onClick={() => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    curConfig,
                                                                );
                                                            _.pullAt(
                                                                tmpBlock,
                                                                audioIdx,
                                                            );
                                                            setCurConfig(
                                                                tmpBlock,
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        disabled={disableWrite}
                                                        icon={<CopyIcon />}
                                                        type="primary"
                                                        shape="circle"
                                                        size="small"
                                                        onClick={() => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    curConfig,
                                                                );
                                                            tmpBlock.splice(
                                                                audioIdx,
                                                                0,
                                                                {
                                                                    ..._.cloneDeep(
                                                                        sticker,
                                                                    ),
                                                                    uniqueId:
                                                                        _.uniqueId(),
                                                                },
                                                            );
                                                            setCurConfig(
                                                                tmpBlock,
                                                            );
                                                            message.info(
                                                                `Duplicated!`,
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        disabled={disableWrite}
                                                        type="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            message.info(
                                                                `Copied to clipboard!`,
                                                            );
                                                            navigator.clipboard.writeText(
                                                                JSON.stringify([
                                                                    _.omit(
                                                                        sticker,
                                                                        [
                                                                            "uniqueId",
                                                                        ],
                                                                    ),
                                                                ]),
                                                            );
                                                        }}
                                                    >
                                                        Copy to clipboard
                                                    </Button>
                                                </Space>

                                                <Form.Item
                                                    label={"x"}
                                                    validateStatus={
                                                        !sticker["x"]
                                                            ? "error"
                                                            : ""
                                                    }
                                                    help={
                                                        !sticker["x"]
                                                            ? "Required Field"
                                                            : ""
                                                    }
                                                >
                                                    <Input
                                                        disabled={disableWrite}
                                                        value={sticker["x"]}
                                                        placeholder="Type here..."
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(e) => {
                                                            let value =
                                                                e.target.value;
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    curConfig,
                                                                );
                                                            _.set(
                                                                tmpBlock,
                                                                [audioIdx, "x"],
                                                                value,
                                                            );
                                                            setCurConfig(
                                                                tmpBlock,
                                                            );
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={"alignment"}
                                                    validateStatus={
                                                        !sticker["alignment"]
                                                            ? "error"
                                                            : ""
                                                    }
                                                    help={
                                                        !sticker["alignment"]
                                                            ? "Required Field"
                                                            : ""
                                                    }
                                                >
                                                    <Select
                                                        value={
                                                            sticker["alignment"]
                                                        }
                                                        placeholder="execution type"
                                                        options={[
                                                            {
                                                                label: "left",
                                                                value: "LEFT",
                                                            },
                                                            {
                                                                label: "right",
                                                                value: "RIGHT",
                                                            },
                                                        ]}
                                                        style={{
                                                            flexGrow: 0,
                                                        }}
                                                        onChange={(
                                                            val: any,
                                                        ) => {
                                                            let value = val;
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    curConfig,
                                                                );
                                                            _.set(
                                                                tmpBlock,
                                                                [
                                                                    audioIdx,
                                                                    "alignment",
                                                                ],
                                                                value,
                                                            );
                                                            setCurConfig(
                                                                tmpBlock,
                                                            );
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={"type"}
                                                    validateStatus={
                                                        !sticker["type"]
                                                            ? "error"
                                                            : ""
                                                    }
                                                    help={
                                                        !sticker["type"]
                                                            ? "Required Field"
                                                            : ""
                                                    }
                                                >
                                                    <Select
                                                        value={sticker["type"]}
                                                        placeholder="file type"
                                                        options={[
                                                            {
                                                                label: "lottie",
                                                                value: "LOTTIE",
                                                            },
                                                            {
                                                                label: "image or gif",
                                                                value: "IMAGE_GIF",
                                                            },
                                                        ]}
                                                        style={{
                                                            flexGrow: 0,
                                                        }}
                                                        onChange={(
                                                            val: any,
                                                        ) => {
                                                            let value = val;
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    curConfig,
                                                                );
                                                            _.set(
                                                                tmpBlock,
                                                                [
                                                                    audioIdx,
                                                                    "type",
                                                                ],
                                                                value,
                                                            );
                                                            setCurConfig(
                                                                tmpBlock,
                                                            );
                                                        }}
                                                        disabled={disableWrite}
                                                    />
                                                </Form.Item>

                                                {sticker["type"] ===
                                                    "IMAGE_GIF" && (
                                                    <>
                                                        <Upload
                                                            customRequest={async (
                                                                props: any,
                                                            ) => {
                                                                try {
                                                                    const fileExtension =
                                                                        props?.file?.name
                                                                            ?.split(
                                                                                ".",
                                                                            )
                                                                            .pop();
                                                                    const {
                                                                        url,
                                                                        bucket_url,
                                                                    } =
                                                                        await getPresignedURL(
                                                                            props
                                                                                ?.file
                                                                                ?.uid,
                                                                            `.${fileExtension}`,
                                                                        );

                                                                    const response =
                                                                        await axios.put(
                                                                            url,
                                                                            props?.file,
                                                                            {
                                                                                headers:
                                                                                    {
                                                                                        "Content-Type":
                                                                                            props
                                                                                                ?.file
                                                                                                ?.type, // Set the content type of the file
                                                                                    },
                                                                            },
                                                                        );

                                                                    if (
                                                                        response?.status ===
                                                                        200
                                                                    ) {
                                                                        let tmpBlock =
                                                                            _.cloneDeep(
                                                                                curConfig,
                                                                            );
                                                                        _.set(
                                                                            tmpBlock,
                                                                            [
                                                                                audioIdx,
                                                                                "image",
                                                                            ],
                                                                            bucket_url,
                                                                        );
                                                                        setCurConfig(
                                                                            tmpBlock,
                                                                        );
                                                                        message.success(
                                                                            "File uploaded successfully",
                                                                        );
                                                                    } else {
                                                                        message.error(
                                                                            "Error in uploading file",
                                                                        );
                                                                    }
                                                                } catch (error) {
                                                                    message.error(
                                                                        "Error in uploading file",
                                                                    );
                                                                    console.log(
                                                                        error,
                                                                    );
                                                                }
                                                            }}
                                                            fileList={[]}
                                                            onChange={() => {}}
                                                            onRemove={() => {}}
                                                            accept={
                                                                "image/png, image/jpeg, image/jpg, image/gif, image/webp"
                                                            }
                                                        >
                                                            <Button
                                                                icon={
                                                                    <UploadOutlined />
                                                                }
                                                            >
                                                                Click to Upload
                                                            </Button>
                                                        </Upload>
                                                        <div
                                                            style={{
                                                                border: "1px solid black",
                                                                padding: "8px",
                                                                borderRadius:
                                                                    "8px",
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    sticker?.image
                                                                }
                                                                alt="image"
                                                                style={{
                                                                    width: "100px",
                                                                    height: "100px",
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                )}

                                                {sticker["type"] ===
                                                    "LOTTIE" && (
                                                    <>
                                                        <Form.Item
                                                            label={"json"}
                                                            validateStatus={
                                                                !sticker["json"]
                                                                    ? "error"
                                                                    : ""
                                                            }
                                                            help={
                                                                !sticker["json"]
                                                                    ? "Required Field"
                                                                    : ""
                                                            }
                                                        >
                                                            <Input.TextArea
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                value={
                                                                    sticker[
                                                                        "json"
                                                                    ]
                                                                }
                                                                placeholder="Type here..."
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    let value =
                                                                        e.target
                                                                            .value;
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            curConfig,
                                                                        );
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            audioIdx,
                                                                            "json",
                                                                        ],
                                                                        value,
                                                                    );
                                                                    setCurConfig(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <div
                                                            style={{
                                                                border: "1px solid black",
                                                                padding: "8px",
                                                                borderRadius:
                                                                    "8px",
                                                            }}
                                                        >
                                                            <Player
                                                                autoplay
                                                                loop
                                                                src={
                                                                    sticker?.json
                                                                }
                                                                style={{
                                                                    width: "100px",
                                                                    height: "100px",
                                                                }}
                                                            />
                                                        </div>
                                                    </>
                                                )}

                                                {/* {Object.keys(sticker)
                                                    .filter(
                                                        (key) =>
                                                            ![
                                                                "sticker",
                                                                "uniqueId",
                                                            ].includes(key),
                                                    )
                                                    .map((key, i) => (
                                                        <Form.Item
                                                            key={i}
                                                            label={key}
                                                            validateStatus={
                                                                !sticker[key]
                                                                    ? "error"
                                                                    : ""
                                                            }
                                                            help={
                                                                !sticker[key]
                                                                    ? "Required Field"
                                                                    : ""
                                                            }
                                                        >
                                                            <Input
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                value={
                                                                    sticker[key]
                                                                }
                                                                placeholder="Type here..."
                                                                style={{
                                                                    width: "50%",
                                                                }}
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    let value =
                                                                        e.target
                                                                            .value;
                                                                    let tmpBlock =
                                                                        _.cloneDeep(
                                                                            curConfig,
                                                                        );
                                                                    _.set(
                                                                        tmpBlock,
                                                                        [
                                                                            audioIdx,
                                                                            key,
                                                                        ],
                                                                        value,
                                                                    );
                                                                    setCurConfig(
                                                                        tmpBlock,
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    ))} */}
                                            </div>
                                        </SortableItemCustom>
                                    );
                                },
                            )}
                        </SortableContainerCustom>
                    </Col>
                </Row>

                {curConfig?.length > 0 && (
                    <Row style={{ paddingBlock: "20px" }}>
                        <Space>
                            <Button
                                size="small"
                                disabled={disableWrite}
                                type="primary"
                                onClick={() => {
                                    message.info(
                                        `Copied sticker config to clipboard!`,
                                    );

                                    navigator.clipboard.writeText(
                                        JSON.stringify(
                                            curConfig.map(
                                                (v: { uniqueId: any }) => {
                                                    delete v.uniqueId;
                                                    return v;
                                                },
                                            ),
                                        ),
                                    );
                                }}
                            >
                                Copy sticker config
                            </Button>
                        </Space>
                    </Row>
                )}

                <Space>
                    <Button
                        type="default"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={disableWrite}
                    >
                        Submit
                    </Button>
                </Space>
            </Modal>
        </div>
    );
};

export default StickerPool;
