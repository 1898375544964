import { BOOK_ROUTES } from "./book";
import { COLLECTION_ROUTES } from "./collection";
import { CONCEPT_ROUTES } from "./concept";
import { CONTENT_ROUTES } from "./content";
import { HOME_ROUTES } from "./home";
import { DOCUMENT_ROUTES } from "./document";
import { LOG_ROUTES } from "./logs";
import { MEME_ROUTES } from "./meme";
import { ORDER_ROUTES } from "./order";
import { PLAN_ROUTES } from "./plan";
import { QUESTION_ROUTES } from "./question";
import { SET_ROUTES } from "./set";
import { STUDENT_ROUTES } from "./student";
import { TAB_ROUTES } from "./tab";
import { USER_ROUTES } from "./user";
import { WORKSHEET_ROUTES } from "./worksheet";
import { TEST_ROUTE } from "../components/HighlightWrapper/Test";
import { GENERIC_DATA_ROUTES } from "./generic-data/updateGenericData";
import { SHARE_PAGE_ROUTE } from "./share/updateSharePage";

export { SetList, SetCreate, SetEdit } from "./set";
export { CollectionList, CollectionCreate, CollectionEdit } from "./collection";
export { QuestionList, QuestionCreate, QuestionEdit } from "./question";
export { StudentList, StudentCreate, StudentEdit } from "./student";
export { MemeList, MemeCreate, MemeEdit } from "./meme";
export { ConceptList, ConceptCreate, ConceptEdit } from "./concept";
export { ContentList, ContentCreate, ContentEdit } from "./content";
export { Home } from "./home";
export { UserList, UserCreate, UserEdit } from "./user";
export { PlanList, PlanCreate, PlanEdit } from "./plan";

export { BookList, BookCreate, BookEdit } from "./book";
export { TopicList, TopicCreate, TopicEdit } from "./book";
export { ChapterList, ChapterCreate, ChapterEdit } from "./book";
export { LogsList } from "./logs";

export {
    TabList,
    TabCreate,
    TabEdit,
    TabBookCreate,
    TabBookEdit,
    TabChapterList,
    TabChapterCreate,
    TabChapterEdit,
    TabTopicList,
    TabTopicCreate,
    TabTopicEdit,
} from "./tab";

export { DocumentList, DocumentCreate, DocumentEdit } from "./document";
export {
    WorksheetList,
    WorksheetCreateEnhanced,
    WorksheetEditEnhanced,
    WorksheetPreview,
    WorksheetLive,
    WorksheetBlockSearch,
} from "./worksheet";

export const ALL_ROUTES = [
    ...HOME_ROUTES,
    ...BOOK_ROUTES,
    ...COLLECTION_ROUTES,
    ...CONCEPT_ROUTES,
    ...CONTENT_ROUTES,
    ...DOCUMENT_ROUTES,
    ...LOG_ROUTES,
    ...MEME_ROUTES,
    ...ORDER_ROUTES,
    ...PLAN_ROUTES,
    ...QUESTION_ROUTES,
    ...SET_ROUTES,
    ...STUDENT_ROUTES,
    ...TAB_ROUTES,
    ...USER_ROUTES,
    ...WORKSHEET_ROUTES,
    ...TEST_ROUTE,
    ...GENERIC_DATA_ROUTES,
    ...SHARE_PAGE_ROUTE,
];
