import {
    AlignCenterOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    BgColorsOutlined,
    TableOutlined,
    BorderOuterOutlined,
    CheckOutlined,
    CloseOutlined,
    EyeInvisibleOutlined,
    FileImageOutlined,
    FileTextOutlined,
    FontColorsOutlined,
    FontSizeOutlined,
    PicCenterOutlined,
    TranslationOutlined,
    VerticalAlignBottomOutlined,
    VerticalAlignMiddleOutlined,
    VerticalAlignTopOutlined,
} from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Popover,
    Row,
    Select,
    Switch,
    Tooltip,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { BlockPicker } from "react-color";
import { RenderUpload, SlateEditor } from "src/components";
import { areAllElementsSame } from "./helper";
import BorderTable from "./BorderTable";
import InputCell from "./InputCell";
import DropdownCell from "./DropdownCell";
import { isString } from "lodash";
import CorrectInputValuesEditor from "../../../../../Figma/renderEditor/CorrectInputValues";
import MinMaxWidthComponent from "../../../../../Figma/renderEditor/MinMaxWidth";
import MinMaxHeight from "../../../../../Figma/renderEditor/MinMaxHeight";
import CellMergeComponent from "./components/CellMergeComponent";
import { isSelectionRectangle } from "./utils";
import DropAreaComponent from "./DropAreaComponent";
import RotationFlow from "../../../../../Figma/renderEditor/RotationFlow";
import GranularBorderTable from "./GranularBorderTable";

const TableToolbar = (props) => {
    const {
        setProperty,
        setBorderProperty,
        selectedCells,
        selectedCellsIndices,
        handlePasteToCells,
        currentEditor,
        setCurrentEditor,
        disableWrite,
        hasMentions,
        mentionsList,
        handleClearSelections,
        table,
        selectedRows = [],
        selectedCols = [],
        setBlock,
    } = props;
    const [load, setLoad] = useState(false);
    const [inputModalOpen, setInputModalOpen] = useState(false);
    const [dropdownModalOpen, setDropdownModalOpen] = useState(false);

    useEffect(() => {
        if (load) setLoad(false);
    }, [load]);

    const [selectedRowHeights, selectedColWidths] = useMemo(() => {
        const rows: any[] = [];
        const cols: any[] = [];

        if (table?.minHeight)
            selectedRows?.forEach((val) => {
                rows.push(table?.minHeight[val]);
            });
        if (table?.minMaxWidth)
            selectedCols?.forEach((val) => {
                cols.push(table?.minMaxWidth[val]);
            });
        return [rows, cols];
    }, [table, selectedRows, selectedCols]);

    console.log("table----", table);
    return (
        <>
            <div
                style={{
                    display: "flex",
                    gap: "5px",
                    flexDirection: "column",
                }}
            >
                {inputModalOpen && (
                    <InputCell
                        isModalOpen={inputModalOpen}
                        setModalOpen={setInputModalOpen}
                        inputCell={{
                            enabled: areAllElementsSame(selectedCells, [
                                "input",
                                "enabled",
                            ]),
                            fill: areAllElementsSame(selectedCells, [
                                "input",
                                "fill",
                            ]),
                            type: areAllElementsSame(selectedCells, [
                                "input",
                                "type",
                            ]),
                            keyboard: areAllElementsSame(selectedCells, [
                                "input",
                                "keyboard",
                            ]),
                            default: areAllElementsSame(selectedCells, [
                                "input",
                                "default",
                            ]),
                            placeholder: areAllElementsSame(selectedCells, [
                                "input",
                                "placeholder",
                            ]),
                            inputChangeFeedback: areAllElementsSame(
                                selectedCells,
                                ["input", "inputChangeFeedback"],
                            ),
                            singleLine: areAllElementsSame(selectedCells, [
                                "input",
                                "singleLine",
                            ]),
                            dimensionControl: areAllElementsSame(
                                selectedCells,
                                ["input", "dimensionControl"],
                            ),
                            config: areAllElementsSame(selectedCells, [
                                "input",
                                "config",
                            ]),
                            inputType:
                                areAllElementsSame(selectedCells, [
                                    "input",
                                    "inputType",
                                ]) || "NUMBER",
                        }}
                        handleSubmit={(v) =>
                            setProperty({
                                type: "INPUT",
                                value: v,
                            })
                        }
                        currentEditor={currentEditor}
                        setCurrentEditor={setCurrentEditor}
                        disableWrite={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                    />
                )}
                {dropdownModalOpen && (
                    <DropdownCell
                        isModalOpen={dropdownModalOpen}
                        setModalOpen={setDropdownModalOpen}
                        inputCell={{
                            enabled: areAllElementsSame(selectedCells, [
                                "dropdown",
                                "enabled",
                            ]),
                            options:
                                areAllElementsSame(selectedCells, [
                                    "dropdown",
                                    "options",
                                ]) || [],
                            type: "latex",
                            //  areAllElementsSame(selectedCells, [
                            //     "dropdown",
                            //     "type",
                            // ]),
                            default: areAllElementsSame(selectedCells, [
                                "dropdown",
                                "default",
                            ]),
                            placeholder: areAllElementsSame(selectedCells, [
                                "dropdown",
                                "placeholder",
                            ]),
                            dimensionControl: areAllElementsSame(
                                selectedCells,
                                ["dropdown", "dimensionControl"],
                            ),
                        }}
                        handleSubmit={(v) =>
                            setProperty({
                                type: "DROPDOWN",
                                value: v,
                            })
                        }
                        currentEditor={currentEditor}
                        setCurrentEditor={setCurrentEditor}
                        disableWrite={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                    />
                )}
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        alignItems: "center",
                    }}
                >
                    <Tooltip placement="top" title={"Hidden Cell"}>
                        <Button
                            onClick={() =>
                                setProperty({
                                    type: "HIDDEN",
                                    value: !areAllElementsSame(
                                        selectedCells,
                                        "hidden",
                                    ),
                                })
                            }
                            type={
                                areAllElementsSame(selectedCells, "hidden")
                                    ? "primary"
                                    : "default"
                            }
                            icon={<EyeInvisibleOutlined />}
                        />
                    </Tooltip>
                    <div
                        style={{
                            border: "1px dashed #5f5f5f",
                            padding: "5px",
                        }}
                    >
                        <Tooltip placement="top" title={"Align Left"}>
                            <Button
                                onClick={() =>
                                    setProperty({
                                        type: "ALIGN",
                                        value: "LEFT",
                                    })
                                }
                                type={
                                    areAllElementsSame(
                                        selectedCells,
                                        "align",
                                        "LEFT",
                                    )
                                        ? "primary"
                                        : "default"
                                }
                                icon={<AlignLeftOutlined />}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={"Align Center"}>
                            <Button
                                onClick={() =>
                                    setProperty({
                                        type: "ALIGN",
                                        value: "CENTER",
                                    })
                                }
                                type={
                                    areAllElementsSame(
                                        selectedCells,
                                        "align",
                                        "CENTER",
                                    )
                                        ? "primary"
                                        : "default"
                                }
                                icon={<AlignCenterOutlined />}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={"Align Right"}>
                            <Button
                                onClick={() =>
                                    setProperty({
                                        type: "ALIGN",
                                        value: "RIGHT",
                                    })
                                }
                                type={
                                    areAllElementsSame(
                                        selectedCells,
                                        "align",
                                        "RIGHT",
                                    )
                                        ? "primary"
                                        : "default"
                                }
                                icon={<AlignRightOutlined />}
                            />
                        </Tooltip>
                    </div>

                    {/*Vertical alignment*/}
                    <div
                        style={{
                            border: "1px dashed #5f5f5f",
                            padding: "5px",
                        }}
                    >
                        <Tooltip placement="top" title={"Align Top"}>
                            <Button
                                onClick={() =>
                                    setProperty({
                                        type: "ALIGN_VERTICAL",
                                        value: "TOP",
                                    })
                                }
                                type={
                                    areAllElementsSame(
                                        selectedCells,
                                        "alignVertical",
                                        "TOP",
                                    )
                                        ? "primary"
                                        : "default"
                                }
                                icon={<VerticalAlignTopOutlined />}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={"Align Center"}>
                            <Button
                                onClick={() =>
                                    setProperty({
                                        type: "ALIGN_VERTICAL",
                                        value: "CENTER",
                                    })
                                }
                                type={
                                    areAllElementsSame(
                                        selectedCells,
                                        "alignVertical",
                                        "CENTER",
                                    )
                                        ? "primary"
                                        : "default"
                                }
                                icon={<VerticalAlignMiddleOutlined />}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={"Align Bottom"}>
                            <Button
                                onClick={() =>
                                    setProperty({
                                        type: "ALIGN_VERTICAL",
                                        value: "BOTTOM",
                                    })
                                }
                                type={
                                    areAllElementsSame(
                                        selectedCells,
                                        "alignVertical",
                                        "BOTTOM",
                                    )
                                        ? "primary"
                                        : "default"
                                }
                                icon={<VerticalAlignBottomOutlined />}
                            />
                        </Tooltip>
                    </div>

                    <div
                        style={{
                            border: "1px dashed #5f5f5f",
                        }}
                    >
                        <Tooltip placement="top" title={"Text"}>
                            <Button
                                icon={<FontSizeOutlined />}
                                onClick={() =>
                                    setProperty({
                                        type: "TEXT",
                                        is_enabled: !areAllElementsSame(
                                            selectedCells,
                                            ["text", "enabled"],
                                        ),
                                    })
                                }
                                type={
                                    areAllElementsSame(selectedCells, [
                                        "text",
                                        "enabled",
                                    ])
                                        ? "primary"
                                        : "default"
                                }
                                style={{ fontWeight: 900 }}
                            />
                        </Tooltip>
                        {areAllElementsSame(selectedCells, [
                            "text",
                            "enabled",
                        ]) && (
                            <>
                                {areAllElementsSame(selectedCells, [
                                    "text",
                                    "type",
                                ]) != "latex" && (
                                    <Tooltip
                                        placement="bottom"
                                        title={"Text Content"}
                                    >
                                        <Popover
                                            content={
                                                <Input
                                                    value={areAllElementsSame(
                                                        selectedCells,
                                                        [
                                                            "text",
                                                            "value",
                                                            "default",
                                                        ],
                                                    )}
                                                    onChange={(e) => {
                                                        setProperty({
                                                            type: "TEXT",
                                                            subType: "value",
                                                            value: e.target
                                                                .value,
                                                        });
                                                    }}
                                                />
                                            }
                                            trigger="click"
                                            title="Text Content"
                                        >
                                            <Button
                                                icon={<FileTextOutlined />}
                                            />
                                        </Popover>
                                    </Tooltip>
                                )}
                                <Tooltip placement="top" title={"Latex"}>
                                    <Popover
                                        content={
                                            <Row
                                                gutter={[5, 5]}
                                                style={{
                                                    width: "500px",
                                                }}
                                            >
                                                <Col span={24}>
                                                    <Form.Item label="Enabled">
                                                        <Switch
                                                            checkedChildren={
                                                                <CheckOutlined />
                                                            }
                                                            unCheckedChildren={
                                                                <CloseOutlined />
                                                            }
                                                            checked={
                                                                areAllElementsSame(
                                                                    selectedCells,
                                                                    [
                                                                        "text",
                                                                        "type",
                                                                    ],
                                                                ) === "latex"
                                                            }
                                                            onChange={(
                                                                value,
                                                            ) => {
                                                                setProperty({
                                                                    type: "TEXT",
                                                                    subType:
                                                                        "type",
                                                                    value: value
                                                                        ? "latex"
                                                                        : "normal",
                                                                });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    {areAllElementsSame(
                                                        selectedCells,
                                                        ["text", "type"],
                                                    ) === "latex" && (
                                                        <SlateEditor
                                                            disabled={
                                                                disableWrite
                                                            }
                                                            hasMentions={
                                                                hasMentions
                                                            }
                                                            mentionsList={
                                                                mentionsList
                                                            }
                                                            id={`cell-text-value-latex`}
                                                            onChange={(
                                                                value: any,
                                                            ) => {
                                                                setProperty({
                                                                    type: "TEXT",
                                                                    subType:
                                                                        "value",
                                                                    value,
                                                                });
                                                            }}
                                                            isFocused={
                                                                currentEditor ===
                                                                `cell-text-value-latex`
                                                            }
                                                            required={false}
                                                            setEditor={(
                                                                id: string,
                                                            ) =>
                                                                setCurrentEditor(
                                                                    id,
                                                                )
                                                            }
                                                            value={
                                                                isString(
                                                                    areAllElementsSame(
                                                                        selectedCells,
                                                                        [
                                                                            "text",
                                                                            "value",
                                                                            "default",
                                                                        ],
                                                                    ) || "",
                                                                )
                                                                    ? []
                                                                    : areAllElementsSame(
                                                                          selectedCells,
                                                                          [
                                                                              "text",
                                                                              "value",
                                                                              "default",
                                                                          ],
                                                                      )
                                                            }
                                                            placeholder={
                                                                "Type here..."
                                                            }
                                                            // v2={true}
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        }
                                        title="Latex"
                                        trigger="click"
                                    >
                                        <Button
                                            type={
                                                areAllElementsSame(
                                                    selectedCells,
                                                    ["text", "type"],
                                                ) === "latex"
                                                    ? "primary"
                                                    : "default"
                                            }
                                            icon={<TranslationOutlined />}
                                        />
                                    </Popover>
                                </Tooltip>
                                <Tooltip placement="top" title={"Font Color"}>
                                    <Popover
                                        content={
                                            <BlockPicker
                                                colors={[]}
                                                color={areAllElementsSame(
                                                    selectedCells,
                                                    [
                                                        "text",
                                                        "color",
                                                        "default",
                                                    ],
                                                )}
                                                onChangeComplete={(
                                                    color: any,
                                                ) => {
                                                    setProperty({
                                                        type: "TEXT",
                                                        subType: "color",
                                                        value: color.hex,
                                                    });
                                                }}
                                                triangle="hide"
                                            />
                                        }
                                        title="Font Color"
                                        trigger="click"
                                    >
                                        <Button
                                            style={{
                                                color:
                                                    areAllElementsSame(
                                                        selectedCells,
                                                        [
                                                            "text",
                                                            "color",
                                                            "default",
                                                        ],
                                                    ) || "black",
                                            }}
                                            icon={<FontColorsOutlined />}
                                        />
                                    </Popover>
                                </Tooltip>
                                <Tooltip placement="top" title={"Font Size"}>
                                    <Input
                                        // addonAfter={< />}
                                        value={
                                            areAllElementsSame(selectedCells, [
                                                "text",
                                                "size",
                                                "default",
                                            ]) ?? 16
                                        }
                                        type="number"
                                        style={{
                                            width: "70px",
                                            flexGrow: 0,
                                            flexShrink: 0,
                                        }}
                                        onChange={(e) =>
                                            setProperty({
                                                type: "TEXT",
                                                subType: "size",
                                                value: Number(
                                                    e.target.value || 0,
                                                ),
                                            })
                                        }
                                    />
                                </Tooltip>
                            </>
                        )}
                    </div>
                    <CellMergeComponent
                        selectedCells={selectedCells}
                        selectedCellsIndices={selectedCellsIndices}
                        setProperty={setProperty}
                    />
                    {/* <Button onClick={() => {}} size="large" icon={<BoldOutlined />} />
            <Button onClick={() => {}} size="large" icon={<ItalicOutlined />} />
            <Button onClick={() => {}} size="large" style={{ fontWeight: 900 }}>
                W
            </Button> */}
                    <Tooltip placement="top" title={"Background"}>
                        <Popover
                            destroyTooltipOnHide
                            content={
                                <div
                                    style={{
                                        minHeight: "500px",
                                        minWidth: "280px",
                                    }}
                                >
                                    <Button
                                        size="small"
                                        icon={<CloseOutlined />}
                                        onClick={() => {
                                            setProperty({
                                                type: "FILL_COLOR",
                                                value: null,
                                                is_enabled: false,
                                            });
                                        }}
                                    >
                                        Remove
                                    </Button>
                                    <BlockPicker
                                        colors={[]}
                                        color={areAllElementsSame(
                                            selectedCells,
                                            ["fillColor", "default"],
                                        )}
                                        onChangeComplete={(color: any) => {
                                            setProperty({
                                                type: "FILL_COLOR",
                                                value: color.hex,
                                                is_enabled: true,
                                            });
                                        }}
                                        triangle="hide"
                                    />
                                    <Divider />
                                    <Form.Item>
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            checked={
                                                areAllElementsSame(
                                                    selectedCells,
                                                    ["fillColor", "default"],
                                                ) === "transparent"
                                            }
                                            onChange={(checked) => {
                                                setProperty({
                                                    type: "FILL_COLOR",
                                                    value: checked
                                                        ? "transparent"
                                                        : "",
                                                    is_enabled: checked,
                                                });
                                            }}
                                        />
                                        <span style={{ marginLeft: "10px" }}>
                                            Transparent
                                        </span>
                                    </Form.Item>
                                    <Divider />
                                    {!load && (
                                        <>
                                            <Select
                                                style={{ width: "100%" }}
                                                placeholder="Size"
                                                value={
                                                    areAllElementsSame(
                                                        selectedCells,
                                                        [
                                                            "imageBackground",
                                                            "size",
                                                        ],
                                                    ) ?? "contain"
                                                }
                                                onChange={(value) => {
                                                    setProperty({
                                                        type: "FILL_IMAGE",
                                                        subType: "size",
                                                        value,
                                                        is_enabled: true,
                                                    });
                                                }}
                                            >
                                                <Select.Option value="cover">
                                                    Cover
                                                </Select.Option>
                                                <Select.Option value="contain">
                                                    Contain
                                                </Select.Option>
                                            </Select>
                                            <RenderUpload
                                                singleUpload={true}
                                                addExtension={true}
                                                path="home-explore/document/"
                                                onChangeCustom={(e) => {
                                                    setLoad(true);
                                                    const value =
                                                        e?.target?.value;
                                                    // ? replaceCDNUrl(
                                                    //       e.target.value,
                                                    //       e.target.bucket,
                                                    //   )
                                                    // : "";
                                                    setProperty({
                                                        type: "FILL_IMAGE",
                                                        value: value,
                                                        is_enabled: !!value,
                                                    });
                                                }}
                                                label="upload the file"
                                                value={areAllElementsSame(
                                                    selectedCells,
                                                    [
                                                        "imageBackground",
                                                        "src",
                                                        "default",
                                                    ],
                                                )}
                                            />
                                        </>
                                    )}
                                    <Input
                                        placeholder="Add value here"
                                        value={
                                            areAllElementsSame(selectedCells, [
                                                "imageBackground",
                                                "src",
                                                "default",
                                            ]) ?? null
                                        }
                                        onChange={(e) => {
                                            setLoad(true);
                                            const value = e.target.value;
                                            setProperty({
                                                type: "FILL_IMAGE",
                                                value: value,
                                                is_enabled: !!value,
                                            });
                                        }}
                                    />
                                </div>
                            }
                            title="Fill Color"
                            trigger="click"
                        >
                            <Button
                                type={
                                    areAllElementsSame(selectedCells, [
                                        "imageBackground",
                                        "src",
                                        "default",
                                    ])
                                        ? "primary"
                                        : "default"
                                }
                                style={{
                                    background: areAllElementsSame(
                                        selectedCells,
                                        ["fillColor", "default"],
                                    ),
                                    color:
                                        !areAllElementsSame(selectedCells, [
                                            "fillColor",
                                            "default",
                                        ]) ||
                                        areAllElementsSame(selectedCells, [
                                            "fillColor",
                                            "default",
                                        ]) > "#888"
                                            ? "black"
                                            : "white",
                                }}
                                icon={<BgColorsOutlined />}
                            />
                        </Popover>
                    </Tooltip>
                    <Tooltip placement="top" title={"Border"}>
                        <Popover
                            content={
                                <>
                                    <Row
                                        gutter={[5, 5]}
                                        style={{
                                            width: "500px",
                                            border: "2px",
                                        }}
                                    >
                                        <Col span={24}>
                                            <Form.Item label="Enabled">
                                                <Switch
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={areAllElementsSame(
                                                        selectedCells,
                                                        ["border", "enabled"],
                                                    )}
                                                    onChange={(value) => {
                                                        console.log(
                                                            "VALUE",
                                                            value,
                                                            selectedCellsIndices,
                                                            selectedCells,
                                                        );
                                                        setProperty({
                                                            type: "BORDER",
                                                            is_enabled: value,
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {areAllElementsSame(selectedCells, [
                                            "border",
                                            "enabled",
                                        ]) && (
                                            <>
                                                <Col span={24}>
                                                    <Form.Item label="Selective Borders">
                                                        <Switch
                                                            checkedChildren={
                                                                <CheckOutlined />
                                                            }
                                                            unCheckedChildren={
                                                                <CloseOutlined />
                                                            }
                                                            checked={areAllElementsSame(
                                                                selectedCells,
                                                                [
                                                                    "border",
                                                                    "selective",
                                                                ],
                                                            )}
                                                            onChange={(
                                                                value,
                                                            ) => {
                                                                setProperty({
                                                                    type: "BORDER",
                                                                    subType:
                                                                        "selective",
                                                                    value: value,
                                                                });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                {/* //pranjal */}
                                                <Col span={4}>Color</Col>
                                                <Col span={5}>Width</Col>
                                                <Col span={5}>Style</Col>
                                                <Col span={5}>Radius</Col>
                                                <Col span={5}>Opacity</Col>
                                                <BorderTable
                                                    type={""}
                                                    selectedCells={
                                                        selectedCells
                                                    }
                                                    path={[]}
                                                    setProperty={setProperty}
                                                />
                                                <Divider />

                                                {areAllElementsSame(
                                                    selectedCells,
                                                    ["border", "selective"],
                                                ) && (
                                                    <>
                                                        <Col span={6}>
                                                            <Form.Item label="Top Border">
                                                                <Switch
                                                                    checkedChildren={
                                                                        <CheckOutlined />
                                                                    }
                                                                    unCheckedChildren={
                                                                        <CloseOutlined />
                                                                    }
                                                                    checked={areAllElementsSame(
                                                                        selectedCells,
                                                                        [
                                                                            "border",
                                                                            "top",
                                                                            "enabled",
                                                                        ],
                                                                    )}
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        setProperty(
                                                                            {
                                                                                type: "BORDER",
                                                                                subType:
                                                                                    "enabled",
                                                                                path: [
                                                                                    "top",
                                                                                ],
                                                                                value: value,
                                                                            },
                                                                        );
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item label="Bottom Border">
                                                                <Switch
                                                                    checkedChildren={
                                                                        <CheckOutlined />
                                                                    }
                                                                    unCheckedChildren={
                                                                        <CloseOutlined />
                                                                    }
                                                                    checked={areAllElementsSame(
                                                                        selectedCells,
                                                                        [
                                                                            "border",
                                                                            "bottom",
                                                                            "enabled",
                                                                        ],
                                                                    )}
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        setProperty(
                                                                            {
                                                                                type: "BORDER",
                                                                                subType:
                                                                                    "enabled",
                                                                                path: [
                                                                                    "bottom",
                                                                                ],
                                                                                value: value,
                                                                            },
                                                                        );
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item label="Left Border">
                                                                <Switch
                                                                    checkedChildren={
                                                                        <CheckOutlined />
                                                                    }
                                                                    unCheckedChildren={
                                                                        <CloseOutlined />
                                                                    }
                                                                    checked={areAllElementsSame(
                                                                        selectedCells,
                                                                        [
                                                                            "border",
                                                                            "left",
                                                                            "enabled",
                                                                        ],
                                                                    )}
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        setProperty(
                                                                            {
                                                                                type: "BORDER",
                                                                                subType:
                                                                                    "enabled",
                                                                                path: [
                                                                                    "left",
                                                                                ],
                                                                                value: value,
                                                                            },
                                                                        );
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item label="Right Border">
                                                                <Switch
                                                                    checkedChildren={
                                                                        <CheckOutlined />
                                                                    }
                                                                    unCheckedChildren={
                                                                        <CloseOutlined />
                                                                    }
                                                                    checked={areAllElementsSame(
                                                                        selectedCells,
                                                                        [
                                                                            "border",
                                                                            "right",
                                                                            "enabled",
                                                                        ],
                                                                    )}
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        setProperty(
                                                                            {
                                                                                type: "BORDER",
                                                                                subType:
                                                                                    "enabled",
                                                                                path: [
                                                                                    "right",
                                                                                ],
                                                                                value: value,
                                                                            },
                                                                        );
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Col>

                                                        <Divider />

                                                        <Col span={5}>Type</Col>
                                                        <Col span={4}>
                                                            Color
                                                        </Col>
                                                        <Col span={5}>
                                                            Width
                                                        </Col>
                                                        <Col span={5}>
                                                            Style
                                                        </Col>
                                                        <Col span={5}>
                                                            Opacity
                                                        </Col>
                                                        {areAllElementsSame(
                                                            selectedCells,
                                                            [
                                                                "border",
                                                                "top",
                                                                "enabled",
                                                            ],
                                                        ) && (
                                                            <>
                                                                <BorderTable
                                                                    selectedCells={
                                                                        selectedCells
                                                                    }
                                                                    type={"Top"}
                                                                    path={[
                                                                        "top",
                                                                    ]}
                                                                    setProperty={
                                                                        setProperty
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                        {areAllElementsSame(
                                                            selectedCells,
                                                            [
                                                                "border",
                                                                "bottom",
                                                                "enabled",
                                                            ],
                                                        ) && (
                                                            <>
                                                                <BorderTable
                                                                    selectedCells={
                                                                        selectedCells
                                                                    }
                                                                    type={
                                                                        "Bottom"
                                                                    }
                                                                    path={[
                                                                        "bottom",
                                                                    ]}
                                                                    setProperty={
                                                                        setProperty
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                        {areAllElementsSame(
                                                            selectedCells,
                                                            [
                                                                "border",
                                                                "left",
                                                                "enabled",
                                                            ],
                                                        ) && (
                                                            <>
                                                                <BorderTable
                                                                    selectedCells={
                                                                        selectedCells
                                                                    }
                                                                    type={
                                                                        "Left"
                                                                    }
                                                                    path={[
                                                                        "left",
                                                                    ]}
                                                                    setProperty={
                                                                        setProperty
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                        {areAllElementsSame(
                                                            selectedCells,
                                                            [
                                                                "border",
                                                                "right",
                                                                "enabled",
                                                            ],
                                                        ) && (
                                                            <>
                                                                <BorderTable
                                                                    selectedCells={
                                                                        selectedCells
                                                                    }
                                                                    type={
                                                                        "Right"
                                                                    }
                                                                    path={[
                                                                        "right",
                                                                    ]}
                                                                    setProperty={
                                                                        setProperty
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Row>
                                </>
                            }
                            title="Border Settings"
                            trigger="click"
                        >
                            <Button
                                icon={<BorderOuterOutlined />}
                                type={
                                    areAllElementsSame(selectedCells, [
                                        "border",
                                        "enabled",
                                    ])
                                        ? "primary"
                                        : "default"
                                }
                            />
                        </Popover>
                    </Tooltip>
                    <Tooltip placement="top" title={"Granual Border"}>
                        <Popover
                            content={
                                <>
                                    <Row
                                        gutter={[5, 5]}
                                        style={{
                                            width: "500px",
                                            border: "2px",
                                        }}
                                    >
                                        <Col span={24}>
                                            <Form.Item label="Enabled">
                                                <Switch
                                                    checkedChildren={
                                                        <CheckOutlined />
                                                    }
                                                    unCheckedChildren={
                                                        <CloseOutlined />
                                                    }
                                                    checked={areAllElementsSame(
                                                        selectedCells,
                                                        ["border", "enabled"],
                                                    )}
                                                    onChange={(value) => {
                                                        setBorderProperty({
                                                            is_enabled: value,
                                                            value: {
                                                                type: "INNER_BORDERS",
                                                                color: "#3498db",
                                                                width: 2,
                                                                style: "SOLID",
                                                                radius: 0,
                                                                opacity: 1,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {areAllElementsSame(selectedCells, [
                                            "border",
                                            "enabled",
                                        ]) && (
                                            <>
                                                <GranularBorderTable
                                                    setBorderProperty={
                                                        setBorderProperty
                                                    }
                                                    table={table}
                                                />
                                                <Divider />
                                            </>
                                        )}
                                    </Row>
                                </>
                            }
                            title="Granular Border Settings"
                            trigger="click"
                        >
                            <Button
                                icon={<TableOutlined />}
                                type={
                                    areAllElementsSame(selectedCells, [
                                        "border",
                                        "enabled",
                                    ])
                                        ? "primary"
                                        : "default"
                                }
                            />
                        </Popover>
                    </Tooltip>
                    <Tooltip placement="top" title={"Padding"}>
                        <Popover
                            content={
                                <>
                                    <Button
                                        size="small"
                                        icon={<CloseOutlined />}
                                        onClick={() => {
                                            setProperty({
                                                type: "PADDING",
                                                is_enabled: false,
                                            });
                                        }}
                                    >
                                        Remove
                                    </Button>
                                    <Form.Item label="Horizontal">
                                        <Input
                                            type="number"
                                            placeholder="Add value here"
                                            value={areAllElementsSame(
                                                selectedCells,
                                                ["padding", "horizontal"],
                                            )}
                                            onChange={(e) => {
                                                const value = Number(
                                                    e.target.value,
                                                );
                                                setProperty({
                                                    type: "PADDING",
                                                    subType: "horizontal",
                                                    value,
                                                    is_enabled: true,
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Vertical">
                                        <Input
                                            type="number"
                                            placeholder="Add value here"
                                            value={areAllElementsSame(
                                                selectedCells,
                                                ["padding", "vertical"],
                                            )}
                                            onChange={(e) => {
                                                const value = Number(
                                                    e.target.value,
                                                );
                                                setProperty({
                                                    type: "PADDING",
                                                    subType: "vertical",
                                                    value,
                                                    is_enabled: true,
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </>
                            }
                            title="Padding Settings"
                            trigger="click"
                        >
                            <Button
                                icon={<PicCenterOutlined />}
                                type={
                                    areAllElementsSame(selectedCells, [
                                        "padding",
                                        "enabled",
                                    ])
                                        ? "primary"
                                        : "default"
                                }
                            />
                        </Popover>
                    </Tooltip>
                    <Tooltip placement="top" title={"Image"}>
                        <Popover
                            destroyTooltipOnHide
                            content={
                                <div
                                    style={{
                                        minHeight: "260px",
                                        minWidth: "260px",
                                    }}
                                >
                                    {!load && (
                                        <RenderUpload
                                            singleUpload={true}
                                            addExtension={true}
                                            path="home-explore/document/"
                                            onChangeCustom={(e) => {
                                                setLoad(true);
                                                const value = e?.target?.value;
                                                // ? replaceCDNUrl(
                                                //       e.target.value,
                                                //       e.target.bucket,
                                                //   )
                                                // : "";
                                                setProperty({
                                                    type: "IMAGE",
                                                    value: value,
                                                    is_enabled: !!value,
                                                });
                                            }}
                                            label="upload the file"
                                            value={areAllElementsSame(
                                                selectedCells,
                                                ["image", "src", "default"],
                                            )}
                                        />
                                    )}
                                    <Input
                                        placeholder="Add value here"
                                        value={
                                            areAllElementsSame(selectedCells, [
                                                "image",
                                                "src",
                                                "default",
                                            ]) ?? null
                                        }
                                        onChange={(e) => {
                                            setLoad(true);
                                            const value = e.target.value;
                                            setProperty({
                                                type: "IMAGE",
                                                value: value,
                                                is_enabled: !!value,
                                            });
                                        }}
                                    />
                                </div>
                            }
                            title="Image"
                            trigger="click"
                        >
                            <Button
                                icon={<FileImageOutlined />}
                                type={
                                    areAllElementsSame(selectedCells, [
                                        "image",
                                        "src",
                                        "default",
                                    ])
                                        ? "primary"
                                        : "default"
                                }
                            />
                        </Popover>
                    </Tooltip>

                    <Tooltip placement="top" title={"Tappable"}>
                        <Popover
                            destroyTooltipOnHide
                            content={
                                <Row
                                    gutter={[5, 5]}
                                    style={{
                                        width: "300px",
                                    }}
                                >
                                    <Col span={10}>
                                        <Form.Item label="Enabled">
                                            <Switch
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                                checked={areAllElementsSame(
                                                    selectedCells,
                                                    ["tappable", "enabled"],
                                                )}
                                                onChange={(value) => {
                                                    setProperty({
                                                        type: "TAPPABLE",
                                                        is_enabled: value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={14}>
                                        <Form.Item label={"Default tapped"}>
                                            <Switch
                                                checkedChildren={
                                                    <CheckOutlined />
                                                }
                                                unCheckedChildren={
                                                    <CloseOutlined />
                                                }
                                                checked={
                                                    areAllElementsSame(
                                                        selectedCells,
                                                        [
                                                            "tappable",
                                                            "default",
                                                            "selected",
                                                        ],
                                                    ) === "0"
                                                        ? false
                                                        : areAllElementsSame(
                                                              selectedCells,
                                                              [
                                                                  "tappable",
                                                                  "default",
                                                                  "selected",
                                                              ],
                                                          )
                                                }
                                                onChange={(value) => {
                                                    setProperty({
                                                        type: "TAPPABLE",
                                                        path: [
                                                            "default",
                                                            "selected",
                                                        ],
                                                        value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={"Max Tap"}>
                                            <Input
                                                placeholder="Max Tap"
                                                type="number"
                                                min={0}
                                                value={
                                                    areAllElementsSame(
                                                        selectedCells,
                                                        [
                                                            "tappable",
                                                            "max_tap",
                                                            "default",
                                                        ],
                                                    ) ?? null
                                                }
                                                onChange={(e) => {
                                                    const value =
                                                        e.target.value;
                                                    setProperty({
                                                        type: "TAPPABLE",
                                                        path: [
                                                            "max_tap",
                                                            "default",
                                                        ],
                                                        value: Number(value),
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            title="Tappable"
                            trigger="click"
                        >
                            <Button
                                type={"primary"}
                                ghost={
                                    !areAllElementsSame(selectedCells, [
                                        "tappable",
                                        "enabled",
                                    ])
                                }
                            >
                                Tap
                            </Button>
                        </Popover>
                    </Tooltip>
                    {table?.rotationFlow?.enabled && (
                        <Tooltip placement="top" title="Rotation Flow">
                            <RotationFlow
                                rotationFlow={{
                                    enabled: areAllElementsSame(selectedCells, [
                                        "rotationFlow",
                                        "enabled",
                                    ]),
                                    settings: {
                                        isStart: areAllElementsSame(
                                            selectedCells,
                                            [
                                                "rotationFlow",
                                                "settings",
                                                "isStart",
                                            ],
                                        ),
                                        isEnd: areAllElementsSame(
                                            selectedCells,
                                            [
                                                "rotationFlow",
                                                "settings",
                                                "isEnd",
                                            ],
                                        ),
                                        openings: areAllElementsSame(
                                            selectedCells,
                                            [
                                                "rotationFlow",
                                                "settings",
                                                "openings",
                                            ],
                                        ),
                                        images: areAllElementsSame(
                                            selectedCells,
                                            [
                                                "rotationFlow",
                                                "settings",
                                                "images",
                                            ],
                                        ),
                                        rotatable: areAllElementsSame(
                                            selectedCells,
                                            [
                                                "rotationFlow",
                                                "settings",
                                                "rotatable",
                                            ],
                                        ),
                                        supportedRotations: areAllElementsSame(
                                            selectedCells,
                                            [
                                                "rotationFlow",
                                                "settings",
                                                "supportedRotations",
                                            ],
                                        ),
                                        currentRotation: areAllElementsSame(
                                            selectedCells,
                                            [
                                                "rotationFlow",
                                                "settings",
                                                "currentRotation",
                                            ],
                                        ),
                                        rotationUnit: areAllElementsSame(
                                            selectedCells,
                                            [
                                                "rotationFlow",
                                                "settings",
                                                "rotationUnit",
                                            ],
                                        ),
                                        blockRotation: areAllElementsSame(
                                            selectedCells,
                                            [
                                                "rotationFlow",
                                                "settings",
                                                "blockRotation",
                                            ],
                                        ),
                                    },
                                }}
                                setProperty={setProperty}
                                isToolbarElement={true}
                            />
                        </Tooltip>
                    )}
                    <DropAreaComponent
                        selectedCells={selectedCells}
                        areAllElementsSame={areAllElementsSame}
                        setProperty={setProperty}
                        allCategories={
                            table?.dragAndDropSettings?.enabled &&
                            table?.dragAndDropSettings?.allCategories
                        }
                    />
                    <Tooltip placement="top" title={"Input"}>
                        <Button
                            type={"primary"}
                            ghost={
                                !areAllElementsSame(selectedCells, [
                                    "input",
                                    "enabled",
                                ])
                            }
                            onClick={() => setInputModalOpen(true)}
                        >
                            Input
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={"Dropdown"}>
                        <Button
                            type={"primary"}
                            ghost={
                                !areAllElementsSame(selectedCells, [
                                    "dropdown",
                                    "enabled",
                                ])
                            }
                            onClick={() => setDropdownModalOpen(true)}
                        >
                            Dropdown
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title={"Clickable"}>
                        <Button
                            type={"primary"}
                            ghost={
                                !areAllElementsSame(selectedCells, [
                                    "clickable",
                                    "enabled",
                                ])
                            }
                            onClick={() =>
                                setProperty({
                                    type: "CLICKABLE",
                                    is_enabled: !areAllElementsSame(
                                        selectedCells,
                                        ["clickable", "enabled"],
                                    ),
                                })
                            }
                        >
                            Clickable
                        </Button>
                    </Tooltip>
                    <Popover
                        destroyTooltipOnHide
                        content={
                            <CorrectInputValuesEditor
                                cellData={{
                                    correctInputValues:
                                        areAllElementsSame(selectedCells, [
                                            "correctInputValues",
                                        ]) || [],
                                }}
                                setCell={(v) =>
                                    setProperty({
                                        type: "CORRECT_VALUES",
                                        value: v?.correctInputValues || [],
                                    })
                                }
                            />
                        }
                        title="Correct Values"
                        trigger="click"
                    >
                        <Button
                            type={"primary"}
                            ghost={
                                !areAllElementsSame(selectedCells, [
                                    "correctInputValues",
                                ])
                            }
                        >
                            Correct Values
                        </Button>
                    </Popover>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        border: "2px dashed #e0e0e0",
                        padding: "5px",
                    }}
                >
                    <Button
                        type="primary"
                        size="small"
                        onClick={handleClearSelections}
                        ghost
                    >
                        Clear selections
                    </Button>

                    {(selectedRows?.length > 0 || selectedCols?.length > 0) && (
                        <Tooltip
                            placement="top"
                            title={" Adjust Height & width"}
                        >
                            <Popover
                                destroyTooltipOnHide
                                content={
                                    <>
                                        {selectedCols?.length > 0 && (
                                            <>
                                                <MinMaxWidthComponent
                                                    block={
                                                        table.minMaxWidth
                                                            ? {
                                                                  enabled:
                                                                      areAllElementsSame(
                                                                          selectedColWidths,
                                                                          [
                                                                              "enabled",
                                                                          ],
                                                                      ),
                                                                  minWidth:
                                                                      areAllElementsSame(
                                                                          selectedColWidths,
                                                                          [
                                                                              "minWidth",
                                                                          ],
                                                                      ) || 64,
                                                                  maxWidth:
                                                                      areAllElementsSame(
                                                                          selectedColWidths,
                                                                          [
                                                                              "maxWidth",
                                                                          ],
                                                                      ) || 280,
                                                              }
                                                            : {}
                                                    }
                                                    disableWrite={disableWrite}
                                                    setBlock={(val: any) => {
                                                        let tmpBlock =
                                                            _.cloneDeep(table);
                                                        if (
                                                            !tmpBlock.minMaxWidth
                                                        )
                                                            tmpBlock.minMaxWidth =
                                                                {};
                                                        selectedCols?.forEach(
                                                            (i) => {
                                                                tmpBlock.minMaxWidth[
                                                                    i
                                                                ] = val;
                                                            },
                                                        );
                                                        setBlock(tmpBlock);
                                                    }}
                                                />
                                                <Divider />
                                            </>
                                        )}
                                        {selectedRows?.length > 0 && (
                                            <MinMaxHeight
                                                disableWrite={disableWrite}
                                                block={
                                                    table.minHeight
                                                        ? {
                                                              enabled:
                                                                  areAllElementsSame(
                                                                      selectedRowHeights,
                                                                      [
                                                                          "enabled",
                                                                      ],
                                                                  ),
                                                              minHeight:
                                                                  areAllElementsSame(
                                                                      selectedRowHeights,
                                                                      [
                                                                          "minHeight",
                                                                      ],
                                                                  ) || 64,
                                                              maxHeight:
                                                                  areAllElementsSame(
                                                                      selectedRowHeights,
                                                                      [
                                                                          "maxHeight",
                                                                      ],
                                                                  ) || 280,
                                                          }
                                                        : {}
                                                }
                                                setBlock={(value: any) => {
                                                    let tmpBlock =
                                                        _.cloneDeep(table);
                                                    if (!tmpBlock.minHeight)
                                                        tmpBlock.minHeight = {};
                                                    selectedRows?.forEach(
                                                        (i) => {
                                                            tmpBlock.minHeight[
                                                                i
                                                            ] = value;
                                                        },
                                                    );

                                                    setBlock(tmpBlock);
                                                }}
                                            />
                                        )}
                                    </>
                                }
                                title=" Adjust Height & width"
                                trigger="click"
                            >
                                <Button type="primary" size="small" ghost>
                                    Adjust Height & width
                                </Button>
                            </Popover>
                        </Tooltip>
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        border: "2px dashed #e0e0e0",
                        padding: "5px",
                    }}
                >
                    <Tooltip placement="left" title={"Paste copied cell data"}>
                        <Button
                            type="primary"
                            size="small"
                            onClick={handlePasteToCells}
                            ghost
                        >
                            Paste to cells
                        </Button>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title={"Paste copied cell data with tags"}
                    >
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => handlePasteToCells(true, false)}
                            ghost
                        >
                            Paste with tags
                        </Button>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title={"Paste copied cell data with position and size"}
                    >
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => handlePasteToCells(false, true)}
                            ghost
                        >
                            Paste with position
                        </Button>
                    </Tooltip>
                    <Tooltip
                        placement="bottom"
                        title={
                            "Paste copied cell data with tags, position and size"
                        }
                    >
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => handlePasteToCells(true, true)}
                            ghost
                        >
                            Paste with tags and position
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <hr />
        </>
    );
};

export default TableToolbar;
