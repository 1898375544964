import {
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Typography,
    message,
} from "antd";
// import { AudioPicker, BLOCK_TYPES } from "../../common/index";
import { V2_STORY_DEFAULT_VALUE, renderEditorV2Story } from "../../V2Story";
import _, { cloneDeep, set } from "lodash";
import { v4 as uuid } from "uuid";
import React, { useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { Actions } from "../../../../WorksheetEditor/components/Editor/BlocksColumn/Actions";
import { updateKeysAndCopy } from "../../../../WorksheetEditor/helpers/getActionMenuItems";
import { BLOCK_TYPES } from "../../../common/index";
import { LogicModal } from "../../AllInOne/renderEditor/ComponentsEditor/common/LogicModal";
import { SlateEditor } from "src/components";
import StoriesUpload from "./StoriesUpload";
import isValidCallback from "../../V2Story/isValid";
import {
    convertStoriesToV3,
    convertStoriesToV4,
} from "../../ProgressLevel/renderEditor/helper";
import { useLazyQuery } from "@apollo/client";
import { useWorksheetState } from "src/modules/worksheet/components/contexts/WorksheetContext";
import SyncBlockSearchQuery from "src/modules/worksheet/graphql/SyncBlockSearchQuery";
import { WORKSHEET_FROM_BLOCK_ID } from "src/modules/worksheet/graphql";
import { WORKSHEET_BLOCKS_QUERY } from "src/modules/worksheet/graphql";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

const { V2_STORIES_BLOCK, V2_STORY_BLOCK } = BLOCK_TYPES;

const EditorV2Story = renderEditorV2Story;

const { Title } = Typography;

const Editor: React.FC<any> = ({
    block,
    setBlock,
    isEditor,
    setIsEditor,
    isNewBlock,
    setIsNewBlock,
    worksheet,
    currentBlock,
    showDifficultyLevel = false,
    disableWrite,
    isReadOnlyMode,
    renderContextVariables,
    hasMentions,
    mentionsList = [],
    renderLinkSelect,
    previewData,
    setNewJob,
    updateStoriesJob,
    storiesPath,
    isChild,
    chunkSlug,
    isChildStory,
}: any) => {
    const {
        id,
        tmpId,
        data: {
            slug: dataSlug,
            [V2_STORIES_BLOCK]: { name = "", logic, dev_remarks = [] },
            children,
        },
        backend,
        selected_flag,
    } = block;
    let slug =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.slug || []
            : dataSlug;
    // Only assign a new value to selected_flag if it doesn't already exist
    const initialSelectedFlag =
        block?.selected_flag !== undefined
            ? block?.selected_flag
            : block?.tmpId == null
            ? true
            : false;

    // State to manage selected_flag
    const [localSelectedFlag, setLocalSelectedFlag] =
        useState(initialSelectedFlag);
    const [isOldFlow, setIsOldFlow] = useState<boolean | null>(null); // True if tmpId is null, false otherwise
    const [syncBlocks, setSyncBlocks] = useState([]);
    const [worksheetId, setWorksheetId] = useState("");

    // Lazy query to get blocks based on the worksheetId
    const [getWorksheetBlocks, { data: worksheetData }] = useLazyQuery(
        WORKSHEET_BLOCKS_QUERY,
    );

    useEffect(() => {
        // Check if worksheet has `is_synced_block_worksheet` and update block state
        if (worksheet?.is_synced_block_worksheet) {
            const updatedBlock = {
                ...block,
                is_synced_block: true,
            };

            // Call the updateBlock function passed as prop to update the parent or global state
            setBlock(updatedBlock);
        }
    }, []); // This will run whenever worksheet changes

    const handleInputChange = (e) => {
        setWorksheetId(e.target.value);
    };
    const handleSearch = () => {
        if (worksheetId === "") {
            getWorksheetBlocks({
                variables: { worksheet_id: parseInt(worksheetId, 10) },
            });
            if (data?.blocks) {
                const filteredBlocks = data.blocks.filter(
                    (block) =>
                        block.type === "v2_stories" && block.parent_id !== null,
                );

                console.log("sync blocks", data.blocks, filteredBlocks);

                setSyncBlocks(filteredBlocks);
            }
        }
        if (worksheetId) {
            // Fetch the worksheet blocks


            console.log("worksheetid", worksheetId);
            getWorksheetBlocks({
                variables: { worksheet_id: parseInt(worksheetId, 10) },
            });
            // Fetch sync blocks separately
            console.log("blocks", worksheetData);

        }
    };

    useEffect(() => {


        console.log("worksheetdata", worksheetData);



        if (worksheetData !== undefined) {
            const extractAllInOneBlocks = (worksheetData) => {
                let allBlocks = [];

                const traverse = (block) => {
                    if (block) {
                        if (
                            block.type === "v2_stories" &&
                            block.is_synced_block === true
                        ) {
                            allBlocks.push(block);
                        }

                        if (Array.isArray(block.children)) {
                            block.children.forEach(traverse);
                        }

                        // Recursively process nested properties if necessary
                        Object.values(block).forEach((value) => {
                            if (value && typeof value === "object") {
                                traverse(value);
                            }
                        });
                    }
                };

                traverse(worksheetData);

                // Filter unique blocks based on their ID
                const uniqueBlocks = Array.from(
                    new Map(
                        allBlocks.map((block) => [block.id, block]),
                    ).values(),
                );

                return uniqueBlocks;
            };

            const filteredBlocks = extractAllInOneBlocks(worksheetData);

            setSyncBlocks(filteredBlocks);
        }
    }, [worksheetData]);

    const updateBlockState = (newSelectedFlag: any) => {
        const updatedBlock = {
            ...block,
            selected_flag: newSelectedFlag, // Update selected_flag
        };

        // Call the updateBlock function passed as prop to update the parent or global state
        setBlock(updatedBlock);
    };
    const { blockIndex, chunkIndex, publishedBlocksMap }: any =
        useWorksheetState();

    const [currentSubBlock, setCurrentSubBlock] = useState(
        children?.length > 0 ? 0 : -1,
    );
    const [isPlaying, setIsPLaying] = useState(false);
    const [tempChild, setTempChild] = useState("");
    const [tempChildren, setTempChildren] = useState("");
    const [currentEditor, setCurrentEditor] = useState(
        `${id || tmpId}_remarks`,
    );

    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_remarks`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const updateCurrentBlock = (val: number) => {
        setIsPLaying(false);
        setCurrentSubBlock(val);
    };

    useEffect(() => {
        updateCurrentBlock(children?.length > 0 ? 0 : -1);
    }, [currentBlock]);

    const [getSyncBlocks, { data }] = useLazyQuery(SyncBlockSearchQuery);
    const [getMasterWorksheetID, { data: masterWorksheetData }] = useLazyQuery(
        WORKSHEET_FROM_BLOCK_ID,
    );

    useEffect(() => {
        if (!localSelectedFlag) {
            // Only fetch sync blocks if localSelectedFlag is false
            getSyncBlocks();
        }
    }, [getSyncBlocks, localSelectedFlag]); // Only runs if getSyncBlocks or localSelectedFlag changes

    useEffect(() => {
        if (data?.blocks) {
            const filteredBlocks = data.blocks.filter(
                (block) =>
                    block.type === "v2_stories" && block.parent_id !== null,
            );

            setSyncBlocks(filteredBlocks); // Set syncBlocks state with only blocks of type "v2_stories"
        }
    }, [data]); // Runs when data changes
    useEffect(() => {
        if (
            masterWorksheetData &&
            masterWorksheetData.worksheet_worksheet_block_map?.[0]?.worksheet_id
        ) {
            const newWorksheetId =
                masterWorksheetData.worksheet_worksheet_block_map[0]
                    .worksheet_id;

            const currentUrl = window.location.href;

            const baseUrl = currentUrl.split("/update/")[0];
            const updatedUrl = `${baseUrl}/update/${newWorksheetId}`;

            // Save block.master_block to local storage before navigation
            const masterBlockId = block?.master_block; // Replace `block` with the variable holding the current block data
            if (masterBlockId) {
                localStorage.setItem("masterBlockId", masterBlockId);
            }

            // Navigate to the updated URL
            window.location.href = updatedUrl;
        }
    }, [masterWorksheetData, block]);
    const handleDefaultClick = () => {
        setLocalSelectedFlag(true);

        // Update block object with the new selected_flag
        updateBlockState(true);
        setIsOldFlow(true); // If the default block is clicked, switch to the old flow
    };

    useEffect(() => {
        if (block !== null && !block?.tmpId) {
            // Only run the following when block is updated

            // Perform actions that depend on block change
            setLocalSelectedFlag(true); // Set local flag
            updateBlockState(true); // Update block state
            setIsOldFlow(true); // Switch to old flow, for example
        }
    }, [block?.tmpId]); // The effect will run when `block` state changes


    useEffect(() => {
        console.log("block", block);
    }, [block]); // The effect will run when `block` state changes


    const handleCardClick = (clickedBlock: any) => {
        setBlock(clickedBlock); // Update the state with the clicked block
    };

    // Return early if the flow has not been determined yet (avoid flicker)

    useEffect(() => {
        if (block?.tmpId) {
            setIsOldFlow(false);
        } else setIsOldFlow(true);
    }, [block?.tmpId]);

    const editorProps = {
        block: children[currentSubBlock],
        setBlock: (subBlock: any) => {
            let tmpBlock = _.cloneDeep(block);
            tmpBlock = _.set(
                tmpBlock,
                ["data", "children", currentSubBlock],
                subBlock,
            );
            setBlock(tmpBlock);
        },
        isEditor,
        setIsEditor,
        currentBlock: currentSubBlock + 1,
        isNewBlock,
        setIsNewBlock,
        worksheet,
        childBlockCount: children?.length || 0,
        isPlaying,
        setIsPLaying,
        disableWrite,
        isReadOnlyMode,
        hasMentions,
        mentionsList,
        renderLinkSelect,
    };

    const DragHandle = SortableHandle(() => <MenuOutlined />);

    const SortableItem = SortableElement(({ value, child }) => {
        const actionsProps = {
            deleteBlock: () => {
                updateCurrentBlock(currentSubBlock - 1);
                let tmpBlock = _.cloneDeep(block);
                const tmpOptions = [...children];
                _.pullAt(tmpOptions, currentSubBlock);
                tmpBlock = _.set(tmpBlock, ["data", "children"], tmpOptions);

                setBlock(tmpBlock);
            },
            idx: currentSubBlock,
            addBlockCustom: (type: string, index: number, newBlock: any) => {
                let tmpBlock = _.cloneDeep(block);
                const tmpChildren = [...children];
                tmpChildren.splice(index, 0, newBlock);
                tmpBlock = _.set(tmpBlock, ["data", "children"], tmpChildren);
                setBlock(tmpBlock);
                setCurrentSubBlock(index);
            },
            block: child,
        };

        return (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Badge.Ribbon
                    color={"cyan"}
                    text={value + 1}
                    placement={"start"}
                >
                    <Card
                        hoverable
                        style={{
                            borderRadius: "8px",
                            marginBottom: "4px",
                            background:
                                currentSubBlock == value ? "#F1F1F1" : "",
                            borderBottom: !isValidCallback({
                                block: child,
                            })?.isValid
                                ? "3px solid red"
                                : currentSubBlock === value
                                ? "3px solid indigo"
                                : "",
                            boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
                            position: "relative",
                            minWidth: "90px",
                        }}
                        bodyStyle={{
                            background: "transparent",
                        }}
                        onClick={() => {
                            updateCurrentBlock(value);
                        }}
                    >
                        {"Story"}

                        {currentSubBlock === value && !disableWrite && (
                            <div
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "2px",
                                }}
                            >
                                <DragHandle />
                            </div>
                        )}
                    </Card>
                </Badge.Ribbon>

                {currentSubBlock === value && !disableWrite && (
                    <Actions {...actionsProps} />
                )}
            </div>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div
                style={{
                    display: "flex",
                    gap: "30px",
                    width: "100%",
                    flexWrap: "wrap",
                }}
            >
                {items.map((child, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={index}
                        child={child}
                        disabled={disableWrite}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let tmpBlock = _.cloneDeep(block);
        const tmpChildren = arrayMoveImmutable(
            children,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        tmpBlock = _.set(tmpBlock, ["data", "children"], tmpChildren);
        setBlock(tmpBlock);
        updateCurrentBlock(newIndex);
    };

    const [showQuestionList, setShowQuestionList] = useState(false);

    return (
        <Card>
            {(block?.is_modified === true ||
                syncBlocks.some((syncBlock) => syncBlock.id === block.id)) && (
                <div
                    style={{
                        position: "absolute", // Ensures overlay positions relative to parent
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(128, 128, 128, 0.5)", // Semi-transparent grey
                        zIndex: 9999, // Ensure overlay is on top
                        pointerEvents: "auto", // Enable interactions on overlay
                        display: "flex",
                        alignItems: "flex-start",
                        padding: "10px", // Padding around the buttons
                    }}
                >
                    <button
                        style={{
                            padding: "10px 20px",
                            marginRight: "10px", // Space between buttons
                            backgroundColor: "#1890FF",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            const updatedBlock = { ...block };
                            delete updatedBlock.is_modified;
                            delete updatedBlock.master_block;
                            setBlock(updatedBlock); // Assuming setBlock is a state setter function
                        }}
                    >
                        Edit Sync Block
                    </button>

                    <button
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "#1890FF",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            if (block && block.master_block) {
                                getMasterWorksheetID({
                                    variables: {
                                        blockId: block.master_block,
                                    },
                                });
                            }
                        }}
                    >
                        Edit Master Block
                    </button>
                </div>
            )}

            {!block?.id &&

            !localSelectedFlag &&
            !worksheet.is_synced_block_worksheet &&
            worksheet.type === "personalized_learning_v4" ? (
                // If blockColumnProps.block.tmpId is not null and isOldFlow is false, show the search bar and cards
                <div
                    style={{
                        width: "100%",
                        minWidth: "320px",
                        flexShrink: 1,
                        flexGrow: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Center the content
                        justifyContent: "center", // Vertically center content
                        textAlign: "center",
                    }}
                >
                    {/* Search bar */}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            padding: "20px 0",
                        }}
                    >
                        <input
                            type="text"
                            id="worksheet-id"
                            placeholder="Search With Worksheet ID"
                            onChange={handleInputChange}

                            style={{
                                width: "80%",
                                maxWidth: "500px",
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                fontSize: "16px",
                            }}
                        />
                        <button
                            onClick={handleSearch}
                            style={{
                                padding: "10px",
                                border: "none",
                                borderRadius: "4px",
                                backgroundColor: "#007BFF",
                                color: "#fff",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            🔍 {/* Unicode search icon */}
                        </button>
                    </div>

                    {/* Cards display */}
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "20px",
                            justifyContent: "center",
                        }}
                    >
                        {/* Default Block Card (button) */}
                        {!worksheetData && (
                            <button
                                onClick={handleDefaultClick}
                                style={{
                                    width: "200px",
                                    height: "200px",
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "20px",
                                    backgroundColor: "#f9f9f9",
                                    boxSizing: "border-box",
                                    textAlign: "center",
                                    cursor: "pointer", // Makes it clear that it's a button
                                    outline: "none", // Remove the default focus outline
                                    transition: "background-color 0.3s ease", // Smooth background transition
                                }}
                            >
                                <h3>Default Story</h3>
                                <p>This is a default story block</p>
                            </button>
                        )}

                        {/* Example Blocks (buttons) */}
                        {syncBlocks.map((block: any) => (
                            <button
                                key={block?.id}
                                onClick={() => handleCardClick(block)}
                                style={{
                                    width: "200px",
                                    height: "200px",
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "20px",
                                    backgroundColor: "#f0f0f0",
                                    boxSizing: "border-box",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    outline: "none",
                                    transition: "background-color 0.3s ease",
                                }}
                            >
                                <h3>{block?.data?.v2_stories?.name}</h3>{" "}
                                {/* Display only the name */}
                            </button>
                        ))}
                    </div>
                </div>
            ) : (
                // If blockColumnProps.block.tmpId is null or isOldFlow is true, render the original layout
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        flexShrink: 1,
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            flexShrink: 1,
                        }}
                    >
                        {/* Conditional overlay */}

                        <Row gutter={[20, 20]}>
                            {previewData && block?.id && (
                                <>
                                    <Form.Item
                                        label={"Open Preview"}
                                        style={{
                                            border: "1px solid black",
                                            padding: "0 5px",
                                            marginRight: "5px",
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                const queryParams =
                                                    new URLSearchParams();
                                                const jsonData = {
                                                    context: "preview",
                                                    ...previewData,
                                                    progression_activity_id:
                                                        uuid(),
                                                    path_id: uuid(),
                                                    ...(!isChildStory
                                                        ? {
                                                              variant_id:
                                                                  block?.id,
                                                              block_id:
                                                                  block?.id,
                                                          }
                                                        : {
                                                              story_id:
                                                                  block?.id,
                                                          }),
                                                };

                                                // Add parameters from JSON object to the query string
                                                for (const key in jsonData) {
                                                    if (
                                                        jsonData.hasOwnProperty(
                                                            key,
                                                        )
                                                    ) {
                                                        queryParams.append(
                                                            key,
                                                            jsonData[key],
                                                        );
                                                    }
                                                }

                                                // Construct the URL with the parameters
                                                const url = `${
                                                    process.env
                                                        .REACT_APP_CLIENT_PREVIEW
                                                }/home/worksheet/${
                                                    worksheet?.id
                                                }/${
                                                    previewData[
                                                        "progression_id"
                                                    ]
                                                }?${queryParams.toString()}&logs=true&showQuestion=true${
                                                    showQuestionList
                                                        ? "&questionText=true"
                                                        : ""
                                                }`;

                                                // Open the URL in a new tab
                                                window.open(url, "_blank");
                                            }}
                                            disabled={
                                                !previewData["progression_id"]
                                            }
                                            style={{
                                                marginRight: "10px",
                                            }}
                                        >
                                            QA
                                        </Button>
                                        {/* <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                const queryParams = new URLSearchParams();
                                const jsonData = {
                                    context: "preview",
                                    ...previewData,
                                    progression_activity_id: uuid(),
                                    path_id: uuid(),
                                    ...(!isChildStory
                                        ? {
                                            variant_id: block?.id,
                                            block_id: block?.id,
                                        }
                                        : {
                                            story_id: block?.id,
                                        }),
                                };

                                // Add parameters from JSON object to the query string
                                for (const key in jsonData) {
                                    if (jsonData.hasOwnProperty(key)) {
                                        queryParams.append(key, jsonData[key]);
                                    }
                                }

                                // Construct the URL with the parameters
                                const url = `https://mathai.ai/home/worksheet/${worksheet?.id
                                    }/${previewData["progression_id"]
                                    }?${queryParams.toString()}&logs=true&showQuestion=true`;

                                // Open the URL in a new tab
                                window.open(url, "_blank");
                            }}
                            disabled={!previewData["progression_id"]}
                        >
                            MathAi
                        </Button> */}
                                    </Form.Item>
                                </>
                            )}

                            {!block?.id && (
                                <Button
                                    danger
                                    size="small"
                                    style={{
                                        margin: "0 16px",
                                    }}
                                    type="primary"
                                    onClick={() => {
                                        if (
                                            worksheet?.type ===
                                            "personalized_learning_v4"
                                        )
                                            return;
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock.id = uuid();
                                        tmpBlock.tmpId = uuid();
                                        setBlock(tmpBlock);
                                    }}
                                >
                                    {worksheet?.type ===
                                    "personalized_learning_v4"
                                        ? "Save Worksheet to preview"
                                        : "Set Block ID"}
                                </Button>
                            )}
                            {!disableWrite && (
                                <Button
                                    disabled={disableWrite}
                                    type="primary"
                                    onClick={() => {
                                        message.info(
                                            `Copied Stories to clipboard!`,
                                        );

                                        navigator.clipboard.writeText(
                                            JSON.stringify(block),
                                        );
                                    }}
                                    size="small"
                                >
                                    Copy the stories block to Clipboard
                                </Button>
                            )}
                            {!isChildStory &&
                                worksheet?.type ===
                                    "personalized_learning_v4" &&
                                block?.id && (
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "15px",
                                        }}
                                    >
                                        <Form.Item
                                            style={{
                                                marginLeft: "10px",
                                            }}
                                            label="Stories Id"
                                        >
                                            <Input.Search
                                                value={block.id}
                                                onChange={() => {}}
                                                enterButton="Copy"
                                                size="small"
                                                onSearch={() => {
                                                    message.info(
                                                        `Copied Id to clipboard!`,
                                                    );
                                                    navigator.clipboard.writeText(
                                                        block.id,
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                        {worksheet?.type ===
                                            "personalized_learning_v4" && (
                                            <Form.Item label="Published Stories Id">
                                                {publishedBlocksMap[
                                                    block.id
                                                ] ? (
                                                    <Input.Search
                                                        value={
                                                            publishedBlocksMap[
                                                                block.id
                                                            ]
                                                        }
                                                        onChange={() => {}}
                                                        enterButton="Copy"
                                                        size="small"
                                                        onSearch={() => {
                                                            message.info(
                                                                `Copied Id to clipboard!`,
                                                            );
                                                            navigator.clipboard.writeText(
                                                                block.id,
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    <span>
                                                        Not published Yet
                                                    </span>
                                                )}
                                            </Form.Item>
                                        )}
                                    </div>
                                )}

                            <Col span={16}>
                                <Row gutter={[20, 20]}>
                                    <Col span={12}>
                                        <Title
                                            level={4}
                                            editable={
                                                disableWrite
                                                    ? false
                                                    : {
                                                          text: name,
                                                          onChange: (val) => {
                                                              let tmpBlock =
                                                                  cloneDeep(
                                                                      block,
                                                                  );
                                                              tmpBlock = set(
                                                                  tmpBlock,
                                                                  [
                                                                      "data",
                                                                      V2_STORIES_BLOCK,
                                                                      "name",
                                                                  ],
                                                                  val,
                                                              );
                                                              setBlock(
                                                                  tmpBlock,
                                                              );
                                                          },
                                                          triggerType: [
                                                              "icon",
                                                              "text",
                                                          ],
                                                          enterIcon: null,
                                                      }
                                            }
                                        >
                                            {name}
                                        </Title>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Slug">
                                            <Input
                                                value={slug}
                                                disabled={disableWrite}
                                                onChange={(e) => {
                                                    const value =
                                                        e.target.value;
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        worksheet?.type ===
                                                            "personalized_learning_v4"
                                                            ? [
                                                                  "backend",
                                                                  "slug",
                                                              ]
                                                            : ["data", "slug"],
                                                        value,
                                                    );
                                                    setBlock(tmpBlock);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        span={
                                            (worksheet?.type ===
                                            "personalized_learning_v4"
                                                ? backend
                                                : logic) && !isChild
                                                ? 16
                                                : 24
                                        }
                                    >
                                        <h3
                                            style={{
                                                fontWeight: "bold",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            Dev Remarks
                                        </h3>
                                        <SlateEditor
                                            disabled={disableWrite}
                                            hasMentions={hasMentions}
                                            mentionsList={mentionsList}
                                            id={`${id || tmpId}_remarks`}
                                            onChange={(value: any) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                tmpBlock = _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        V2_STORIES_BLOCK,
                                                        "dev_remarks",
                                                    ],
                                                    value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            isFocused={
                                                !disableWrite &&
                                                currentEditor ===
                                                    `${id || tmpId}_remarks`
                                            }
                                            setEditor={(id: string) =>
                                                setCurrentEditor(id)
                                            }
                                            value={dev_remarks}
                                            placeholder={"Type here..."}
                                            required={false}

                                        />
                                    </Col>
                                    <Col span={8}>
                                        {(worksheet?.type ===
                                        "personalized_learning_v4"
                                            ? backend
                                            : logic) &&
                                            !isChild && (
                                                <Form.Item
                                                    label="Update Logic"
                                                    style={{
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    <LogicModal
                                                        blockIndex={blockIndex}
                                                        chunkIndex={chunkIndex}
                                                        blockSlug={slug}
                                                        chunkSlug={chunkSlug}
                                                        disableWrite={
                                                            disableWrite
                                                        }
                                                        computeFUnctions={
                                                            (worksheet?.type ===
                                                            "personalized_learning_v4"
                                                                ? backend.compute_functions
                                                                : logic.compute_functions) ||
                                                            []
                                                        }
                                                        isAllGlobal={true}
                                                        onSave={(val) => {
                                                            let tmpBlock =
                                                                _.cloneDeep(
                                                                    block,
                                                                );
                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                worksheet?.type ===
                                                                    "personalized_learning_v4"
                                                                    ? [
                                                                          "backend",
                                                                          "compute_functions",
                                                                      ]
                                                                    : [
                                                                          "data",
                                                                          V2_STORIES_BLOCK,
                                                                          "logic",
                                                                          "compute_functions",
                                                                      ],
                                                                val,
                                                            );
                                                            setBlock(tmpBlock);
                                                        }}
                                                    />
                                                </Form.Item>
                                            )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                {!disableWrite && (
                                    <Form.Item
                                        label={"Update Stories"}
                                        style={{ maxWidth: "600px" }}
                                    >
                                        <Input.TextArea
                                            disabled={disableWrite}
                                            value={tempChild}
                                            placeholder="Paste Stories here"
                                            onChange={(e) =>
                                                setTempChild(e.target.value)
                                            }
                                        />

                                        <Button
                                            disabled={disableWrite}
                                            style={{ marginTop: "10px" }}
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                try {
                                                    const parsedJson =
                                                        JSON.parse(tempChild);
                                                    if (
                                                        parsedJson?.type !==
                                                        V2_STORIES_BLOCK
                                                    ) {
                                                        message.warn(
                                                            "Invalid JSON",
                                                        );
                                                        return;
                                                    }
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock =
                                                        updateKeysAndCopy(
                                                            _.omit(parsedJson, [
                                                                "id",
                                                                "worksheet_block_map_id",
                                                                "tmpId",
                                                            ]),
                                                            worksheet?.type ===
                                                                "personalized_learning_v4",
                                                        );

                                                    if (
                                                        worksheet?.type ===
                                                        "personalized_learning_v4"
                                                    ) {
                                                        tmpBlock =
                                                            convertStoriesToV4(
                                                                tmpBlock,
                                                            );
                                                    } else if (
                                                        worksheet?.type ===
                                                        "personalized_learning_v3"
                                                    ) {
                                                        tmpBlock =
                                                            convertStoriesToV3(
                                                                tmpBlock,
                                                            );
                                                    }
                                                    if (block.id)
                                                        tmpBlock.id = block.id;
                                                    setBlock(tmpBlock);
                                                    message.success(
                                                        "Stories Added",
                                                    );
                                                    setTempChild("");
                                                } catch (e) {
                                                    // captureException(e);
                                                    message.error(
                                                        "Error in JSON!",
                                                    );
                                                }
                                            }}
                                        >
                                            Update Stories
                                        </Button>
                                    </Form.Item>
                                )}

                                {renderContextVariables &&
                                    renderContextVariables()}
                                <KTWrapper feature="Upload_Stories">
                                    <Form.Item label="Upload Stories">
                                        <StoriesUpload
                                            block={block}
                                            setBlock={(
                                                v: any,
                                                storiesId: any,
                                            ) => {
                                                if (isChildStory)
                                                    setBlock(v, storiesId);
                                                else setBlock(v);
                                                setCurrentSubBlock(-1);
                                            }}
                                            disabled={
                                                disableWrite ||
                                                (!block?.id &&
                                                    worksheet?.type !==
                                                        "personalized_learning_v4")
                                            }
                                            setNewJob={setNewJob}
                                            updateStoriesJob={updateStoriesJob}
                                            storiesPath={storiesPath}
                                        />
                                        {!block?.id &&
                                            worksheet?.type !==
                                                "personalized_learning_v4" && (
                                                <span style={{ color: "red" }}>
                                                    Set id to upload stories
                                                </span>
                                            )}
                                    </Form.Item>
                                </KTWrapper>
                            </Col>
                            <Col span={8}>
                                <KTWrapper feature="Set_block">
                                    <Form.Item label="Set">
                                        <Select
                                            value={
                                                block?.data?.all_in_one?.logic
                                                    ?.sets || []
                                            }
                                            mode="multiple"
                                            onChange={(value) => {
                                                let tmpBlock =
                                                    _.cloneDeep(block);
                                                _.set(
                                                    tmpBlock,
                                                    [
                                                        "data",
                                                        "all_in_one",
                                                        "logic",
                                                        "sets",
                                                    ],
                                                    value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                        >
                                            {[
                                                ...Array(
                                                    parseInt(
                                                        worksheet
                                                            ?.level_settings
                                                            ?.number_of_sets ??
                                                            0,
                                                    ),
                                                ),
                                            ].map((v, i) => {
                                                return (
                                                    <Select.Option
                                                        value={i + 1}
                                                        key={i}
                                                    >
                                                        {i + 1}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </KTWrapper>
                            </Col>
                            {showDifficultyLevel && (
                                <div
                                    style={{
                                        border: "1px dashed #808080",
                                        margin: "10px auto",
                                        padding: "10px 10px",
                                    }}
                                >
                                    <h4 style={{ fontWeight: "bold" }}>
                                        Difficulty Level
                                    </h4>

                                    <Select
                                        disabled={disableWrite}
                                        value={
                                            block?.data?.other?.difficulty_level
                                        }
                                        style={{ width: 120 }}
                                        onChange={(value: any) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock = _.set(
                                                tmpBlock,
                                                [
                                                    "data",
                                                    "other",
                                                    "difficulty_level",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpBlock);
                                        }}
                                    >
                                        <Select.Option
                                            value={`easy`}
                                            key={`easy`}
                                        >
                                            Easy
                                        </Select.Option>
                                        <Select.Option
                                            value={`medium`}
                                            key={`medium`}
                                        >
                                            Medium
                                        </Select.Option>{" "}
                                        <Select.Option
                                            value={`hard`}
                                            key={`hard`}
                                        >
                                            Hard
                                        </Select.Option>
                                    </Select>
                                </div>
                            )}
                            <Title level={5}>Add Single Story</Title>
                            <Col
                                span={24}
                                style={{
                                    display: "flex",
                                    gap: "20px",
                                    width: "100%",
                                    marginBottom: "20px",
                                }}
                            >
                                {!disableWrite && (
                                    <>
                                        <Badge.Ribbon
                                            color={"green"}
                                            text={"+"}
                                            placement={"start"}
                                        >
                                            <Card
                                                hoverable
                                                style={{
                                                    borderRadius: "8px",
                                                    height: "75px",
                                                    marginBottom: "4px",
                                                    borderBottom:
                                                        "3px solid green",
                                                    flexGrow: 0,
                                                    width: "120px",
                                                }}
                                                onClick={() => {
                                                    let tmpBlock =
                                                        _.cloneDeep(block);
                                                    tmpBlock = _.set(
                                                        tmpBlock,
                                                        [
                                                            "data",
                                                            "children",
                                                            children.length,
                                                        ],
                                                        {
                                                            ...V2_STORY_DEFAULT_VALUE,
                                                            order:
                                                                children.length +
                                                                1,
                                                            tmpId: uuid(),
                                                            id: uuid(),
                                                        },
                                                    );
                                                    setBlock(tmpBlock);
                                                    updateCurrentBlock(
                                                        children.length,
                                                    );
                                                }}
                                            >
                                                {"Add Story"}
                                            </Card>
                                        </Badge.Ribbon>
                                        <div
                                            style={{
                                                display: "flex",
                                                minWidth: "400px",
                                            }}
                                        >
                                            <Input.TextArea
                                                disabled={disableWrite}
                                                value={tempChildren}
                                                placeholder="Paste Story here"
                                                onChange={(e) =>
                                                    setTempChildren(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            <Button
                                                disabled={disableWrite}
                                                type="primary"
                                                onClick={() => {
                                                    try {
                                                        const parsedJson =
                                                            JSON.parse(
                                                                tempChildren,
                                                            );
                                                        if (
                                                            parsedJson?.type !==
                                                            V2_STORY_BLOCK
                                                        ) {
                                                            message.warn(
                                                                "Invalid JSON",
                                                            );
                                                            return;
                                                        }
                                                        let tmpBlock =
                                                            _.cloneDeep(block);
                                                        if (parsedJson) {
                                                            tmpBlock = _.set(
                                                                tmpBlock,
                                                                [
                                                                    "data",
                                                                    "children",
                                                                    children.length,
                                                                ],
                                                                updateKeysAndCopy(
                                                                    _.omit(
                                                                        parsedJson,
                                                                        [
                                                                            "id",
                                                                            "worksheet_block_map_id",
                                                                            "tmpId",
                                                                        ],
                                                                    ),
                                                                    false,
                                                                ),
                                                            );
                                                            setBlock(tmpBlock);
                                                            updateCurrentBlock(
                                                                children.length,
                                                            );
                                                            message.success(
                                                                "Story Added",
                                                            );
                                                            setTempChildren("");
                                                        }
                                                    } catch (e) {
                                                        // captureException(e);
                                                        message.error(
                                                            "Error in JSON!",
                                                        );
                                                    }
                                                }}
                                            >
                                                Add Story
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </Col>

                            <Col span={24}>
                                {children?.length ? (
                                    <SortableList
                                        items={children}
                                        onSortEnd={onSortEnd}
                                        axis="xy"
                                        useDragHandle
                                    />
                                ) : (
                                    <div
                                        style={{
                                            fontSize: "24px",
                                            color: "red",
                                        }}
                                    >
                                        No Stories Added
                                    </div>
                                )}
                                <hr />
                            </Col>
                            <Col span={24}>
                                {currentSubBlock !== -1 &&
                                    children[currentSubBlock] && (
                                        <EditorV2Story {...editorProps} />
                                    )}
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </Card>
    );
};

export default Editor;
