import { BLOCK_TYPES } from "../../common/index";
import {
    COMPONENT_CONDITION_SCHEMA,
    COMPONENT_FIGMA_SCHEMA,
    COMPONENT_RICH_TEXT_SCHEMA,
    COMPONENT_GENERIC_RENDERER_SCHEMA,
    COMPONENT_SURFER_GAME_SCHEMA,
    COMPONENT_BALANCE_GAME_SCHEMA,
} from "./renderEditor/helpers/constants";
const { ALL_IN_ONE } = BLOCK_TYPES;

export const COMPONENT_RENDER_TYPES = {
    PIXI: "pixi",
    FIGMA_HTML: "figma_html",
    GEOGEBRA: "geogebra",
    PREDEFINED: "predefined",
};

export const COMPONENT_TYPES = {
    GENERIC_TABLE: "generic_table",
    DRAG_DROP: "drag_drop",
    MULTI_INPUT: "multi_input",
    TIMER: "timer",
    RICH_TEXT: "rich_text",
    TAP_AND_SELECT: "tap_and_select",
    MULTI_SELECT_INPUT: "multiselect_input",
    GEOGEBRA: "geogebra",
    CONDITION: "condition",
    VOICE_INPUT: "voice_input",
    GENERIC_RENDERER: "generic_renderer",
    SURFER_GAME: "surfer_game",
    BALANCE_GAME: "balance_game",
};

export const COMPONENTS = [
    {
        label: "Generic Table",
        value: COMPONENT_TYPES.GENERIC_TABLE,
        figma_url:
            "https://figma-renderer.vercel.app/figma_screen%7CR4wOq2Ul0EbxviP06SwzYN%7C2735:444",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_FIGMA_SCHEMA,
        owners: [
            {
                name: "Lokesh Rana",
                contributions: [],
            },
            {
                name: "Ishaan Ajmeri",
                contributions: ["Tap & Select"],
            },
            {
                name: "Sammit Badodekar",
                contributions: [
                    "Timer",
                    "Animated Rows",
                    "Table Effects",
                    "Gamify Effects",
                ],
            },
        ],
    },
    {
        label: "Drag & Drop",
        value: COMPONENT_TYPES.DRAG_DROP,
        figma_url:
            "https://figma-renderer.vercel.app/figma_screen%7CoBj9bSNyvAvkOyh1omZyq8%7C11687:661%7Cdev",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_FIGMA_SCHEMA,
        owners: [
            {
                name: "Rishabh Kohale",
                contributions: [],
            },
        ],
    },
    {
        label: "Multi Input",
        value: COMPONENT_TYPES.MULTI_INPUT,
        figma_url:
            "https://figma-renderer.vercel.app/figma_screen|oBj9bSNyvAvkOyh1omZyq8|11352:687",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_FIGMA_SCHEMA,
        owners: [
            {
                name: "Vinay Sarda",
                contributions: [],
            },
        ],
    },
    {
        label: "Tap & Select",
        value: COMPONENT_TYPES.TAP_AND_SELECT,
        figma_url:
            "https://figma-renderer.vercel.app/figma_screen%7CoBj9bSNyvAvkOyh1omZyq8%7C14962:440",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_FIGMA_SCHEMA,
        owners: [
            {
                name: "Ishaan Ajmeri",
                contributions: [],
            },
        ],
    },
    {
        label: "Timer",
        value: COMPONENT_TYPES.TIMER,
        figma_url:
            "https://figma-renderer.vercel.app/figma_screen%7CoBj9bSNyvAvkOyh1omZyq8%7C13387:383%7Cdev",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_FIGMA_SCHEMA,
        owners: [
            {
                name: "Sammit Badodekar",
                contributions: [],
            },
        ],
    },
    {
        label: "Rich Text",
        value: COMPONENT_TYPES.RICH_TEXT,
        component_type: COMPONENT_RENDER_TYPES.PREDEFINED,
        data: COMPONENT_RICH_TEXT_SCHEMA,
        owners: [
            {
                name: "Ishaan Ajmeri",
                contributions: [],
            },
        ],
    },
    {
        label: "Multiselect Input",
        value: COMPONENT_TYPES.MULTI_SELECT_INPUT,
        figma_url:
            "https://figma-renderer.vercel.app/figma_screen|oBj9bSNyvAvkOyh1omZyq8|16101:440|dev",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_FIGMA_SCHEMA,
        owners: [
            {
                name: "Sammit Badodekar",
                contributions: [],
            },
        ],
    },
    {
        label: "Geogebra",
        value: COMPONENT_TYPES.GEOGEBRA,
        figma_url:
            "https://figma-renderer.vercel.app/figma_screen|oBj9bSNyvAvkOyh1omZyq8|16508:440|dev",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_FIGMA_SCHEMA,
        owners: [
            {
                name: "Sammit Badodekar",
                contributions: [],
            },
        ],
    },
    {
        label: "Condition",
        value: COMPONENT_TYPES.CONDITION,
        component_type: COMPONENT_RENDER_TYPES.PREDEFINED,
        data: COMPONENT_CONDITION_SCHEMA,
        owners: [
            {
                name: "Rishabh Kohale",
                contributions: [],
            },
        ],
    },
    {
        label: "Voice Input",
        value: COMPONENT_TYPES.VOICE_INPUT,
        figma_url:
            "https://figma-renderer.vercel.app/figma_screen|oBj9bSNyvAvkOyh1omZyq8|16947:440|dev",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_FIGMA_SCHEMA,
        owners: [
            {
                name: "Sammit Badodekar",
                contributions: [],
            },
        ],
    },
    {
        label: "Generic Renderer",
        value: COMPONENT_TYPES.GENERIC_RENDERER,
        figma_url: "",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_GENERIC_RENDERER_SCHEMA,
        owners: [
            {
                name: "Sammit Badodekar",
                contributions: [],
            },
        ],
    },
    {
        label: "Surfer Game",
        value: COMPONENT_TYPES.SURFER_GAME,
        figma_url: "",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_SURFER_GAME_SCHEMA,
        owners: [
            {
                name: "Vinay Sarda",
                contributions: [],
            },
        ],
    },
    {
        label: "Balance Game",
        value: COMPONENT_TYPES.BALANCE_GAME,
        figma_url: "",
        component_type: COMPONENT_RENDER_TYPES.FIGMA_HTML,
        data: COMPONENT_BALANCE_GAME_SCHEMA,
        owners: [
            {
                name: "Ishaan Ajmeri",
                contributions: [],
            },
        ],
    },
];
const COMPUTE_FUNCS: any = [
    {
        name: "updateStartAudio",
        output: "function updateStartAudio(is_correct, componentsList) {\n  const tempList = Array.isArray(componentsList) ? componentsList : JSON.parse(componentsList);\n  if (['true', true, 1, '1'].includes(is_correct)) {\n    return 'globalFeedback_all_correct';\n  }\n  for (let index = 0; index < tempList.length; index++) {\n    const element = tempList[index];\n    if (element.correct) {\n      return 'globalFeedback_some_correct';\n    }\n  }\n  return 'globalFeedback_all_incorrect';\n}",
        params: ["is_correct", "componentsList"],
        label: "Update Start Audio",
        isGlobal: true,
    },

    {
        name: "updateSimpleInput",
        output: "function updateSimpleInput(componentsList) {\n    let list = Array.isArray(componentsList)\n        ? componentsList\n        : JSON.parse(componentsList);\n    return list.map((v) => v.value);\n}",
        params: ["componentsList"],
        label: "Simplify Input",
        isGlobal: true,
    },
    {
        name: "updateFeedbackType",
        output: "function updateFeedbackType(type, components, simple_inputs) {\n return type;\n}",
        params: ["type", "components", "simple_inputs"],
        label: "Update Feedback Type",
        isGlobal: true,
    },
    {
        name: "updateStory",
        output: "function updateStory(is_correct, componentsList) {\n  return '';\n}",
        params: ["is_correct", "componentsList", "simple_inputs"],
        label: "Update Story",
        isGlobal: true,
    },
    {
        name: "updateEndAudio",
        output: "function updateEndAudio(is_correct, componentsList, simple_inputs) {\n  const tempList = Array.isArray(componentsList) ? componentsList : JSON.parse(componentsList);\n  if (['true', true, 1, '1'].includes(is_correct)) {\n    return 'globalFeedback_all_correct';\n  }\n  for (let index = 0; index < tempList.length; index++) {\n    const element = tempList[index];\n    if (element.correct) {\n      return 'globalFeedback_some_correct';\n    }\n  }\n  return 'globalFeedback_all_incorrect';\n}",
        params: ["is_correct", "componentsList", "simple_inputs"],
        label: "Update End Audio",
        isGlobal: true,
    },
    {
        name: "updateStarCalculation",
        output: `function updateStarCalculation(lives,global_context_variables) {\n  return 1;\n}`,
        params: ["lives", "global_context_variables"],
        label: "Update Star Calculation",
        isGlobal: true,
    },
    {
        name: "getNextBlock",
        output: `function getNextBlock(history, next, global_context_variables) {
            const curr = history[history.length - 1];
            const currentQuestion = curr?.question;
            const isLogicCorrect = curr.submission?.is_correct;
            if (isLogicCorrect) return global_context_variables["next"];
            return next;
        }`,
        params: ["history", "next", "global_context_variables"],
        label: "Get Next Block",
        isGlobal: true,
    },
    {
        name: "getNextConfig",
        output: `function getNextConfig(currentConfig, lives, is_correct, reset, global_context_variables) {
            console.log("nextConfig: global_context_variables", currentConfig, lives, is_correct, reset, global_context_variables)
            const type = currentConfig.type;
            const configs = [
                {
                    type: "get_dynamic_block",
                },
                {
                    type: "show_result",
                },
                {
                    type: "dynamic_block",
                },
                is_correct || lives == 1
                    ? {
                        type: "next_question",
                    }
                    : {
                        type:
                            String(reset) == "true"
                                ? "new_attempt"
                                : "new_attempt_prefilled",
                    },
            ];
            const idx = configs.findIndex((c) => c.type === type);
            if (idx == -1) return configs[0];
            return configs[idx + 1];
        }`,
        params: [
            "currentConfig",
            "lives",
            "is_correct",
            "reset",
            "global_context_variables",
        ],
        label: "Get Next Config",
        isGlobal: true,
    },
    {
        name: "getDynamicBlock",
        output: `function getDynamicBlock(lives, inputs, is_correct, global_context_variables) {
            if (lives == 2) {
                return null;
            }
            if (lives == 1 && !is_correct) {
                return { type: "manual_story", storyId: "name:solution" };
            }
            return null;
        }`,
        params: ["lives", "inputs", "is_correct", "global_context_variables"],
        label: "Get Dynamic Block",
        isGlobal: true,
    },
    {
        name: "toastAudio",
        output: `function toastAudio(){
            return "audio_name"
            }`,
        params: [],
        label: "Toast Audio",
        isGlobal: true,
    },
    {
        name: "toastMessage",
        output: `function toastMessage(){
            return "time ran out"
            }`,
        params: [],
        label: "Toast Message",
        isGlobal: true,
    },
    {
        name: "evaluationFallback",
        output: `function evaluationFallback() {
            return {
                audio: null,
                story: null,
                subtitle: null,
                };
            }`,
        params: [],
        label: "Evaluation Fallback",
        isGlobal: true,
    },
    {
        name: "showStartEvaluationSticker",
        output: `function showStartEvaluationSticker(){
            return ""
            }`,
        params: [],
        label: "Show Start Evaluation Sticker",
        isGlobal: true,
    },
    {
        name: "showEndEvaluationSticker",
        output: `function showEndEvaluationSticker(){
            return ""
            }`,
        params: [],
        label: "Show End Evaluation Sticker",
        isGlobal: true,
    },
    {
        name: "showSubmissionSticker",
        output: `function showEndEvaluationSticker(){
            return ""
            }`,
        params: [],
        label: "Show Submission Sticker",
        isGlobal: true,
    },
    {
        name: "showTryAgainSticker",
        output: `function showEndEvaluationSticker(){
            return ""
            }`,
        params: [],
        label: "Show Try Again Sticker",
        isGlobal: true,
    },
    {
        name: "showInactiveSticker",
        output: `function showEndEvaluationSticker(){
            return ""
            }`,
        params: [],
        label: "Show Inactive Sticker",
        isGlobal: true,
    },
    {
        name: "showStartAttemptSticker",
        output: `function showEndEvaluationSticker(){
            return ""
            }`,
        params: [],
        label: "Show Start Attempt Sticker",
        isGlobal: true,
    },
];

export const COMPONENT_SCHEMA = {
    type: "",
    name: "Default component title",
    description: "Default component description",
    image: "",
    component_type: "",
    skip_evaluation: false,
    disable_interaction: false,
    show_in_preview: false,
    show_as_answer: false,
    answer_slide_number: 1,
};

export const DEFAULT_BLOCK_SCHEMA = (type = "personalized_learning_v3") => {
    if (type === "personalized_learning_v4")
        return {
            type: ALL_IN_ONE,
            data: {
                [ALL_IN_ONE]: {
                    name: "Default block title",
                    logic: {
                        check_type: "ONE_BY_ONE",
                        feedback_type: "NO_FEEDBACK",
                        show_answer: false,
                        show_answer_when: "ALL_LIVES_LOST",
                        has_highlight: false,
                        has_stroke: false,
                        variables: [
                            {
                                name: "total_lives",
                                objectType: "NUMBER",
                                value: 2,
                            },
                            {
                                name: "is_reset",
                                objectType: "BOOLEAN",
                                value: true,
                            },
                        ],
                    },
                    duration_link: {},
                    question_audio: [],
                    dev_remarks: [],
                    components: [
                        // COMPONENT
                    ],
                },
                other: {
                    duration: -1,
                    audio: "",
                    description: [],
                    stories: [],
                },
            },
            backend: {
                badge_count: 1,
                question_derived_variables: [],
                question_variables: [{ name: "next", value: "next" }],
                slug: "",
                compute_functions: COMPUTE_FUNCS,
            },
        };

    return {
        type: ALL_IN_ONE,
        data: {
            slug: "",
            [ALL_IN_ONE]: {
                name: "Default block title",
                badge_count: 1,
                logic: {
                    check_type: "ONE_BY_ONE",
                    feedback_type: "NO_FEEDBACK",
                    compute_functions: COMPUTE_FUNCS,
                    has_highlight: false,
                    has_stroke: false,
                    variables: [
                        {
                            name: "total_lives",
                            objectType: "NUMBER",
                            value: 2,
                        },
                        {
                            name: "is_reset",
                            objectType: "BOOLEAN",
                            value: true,
                        },
                    ],
                },
                duration_link: {},
                question_derived_variables: [],
                question_variables: [],
                question_audio: [],
                dev_remarks: [],
                components: [
                    // COMPONENT
                ],
                generating_audio_config: {
                    audio: "",
                    feedback: {},
                },
            },
            other: {
                duration: -1,
                audio: "",
                description: [],
                stories: [],
            },
        },
    };
};

export default DEFAULT_BLOCK_SCHEMA;

export const StickerFunctions = {
    multiInput: [
        {
            name: "updateStartSticker",
            type: "JAVASCRIPT",
            label: "update start sticker",
            output: `function updateStartAudio(is_correct, componentsList) {
                return 'question_audio_checking';
              }`,
            params: ["is_correct", "componentsList"],
            isGlobal: true,
            forceGlobal: true,
        },
        {
            name: "updateEndSticker",
            type: "JAVASCRIPT",
            label: "update End sticker",
            output: `function updateEndAudio(is_correct, componentsList, pixiData) {
                const tempList = Array.isArray(componentsList) ? componentsList : JSON.parse(componentsList);
                if (['true', true, 1, '1'].includes(is_correct)) {
                  return 'question_audio_correct';
                }
                const totalLives = pixiData.globalContext.parent_variables?.find((i) =>
                  i.name.includes("total_lives"),
                );
                const totalLivesVal = totalLives.default ?? totalLives.value;
                if (totalLivesVal == 2 && !is_correct) {
                  return "question_audio_incorrect_1st_attempt";
                }
                if (totalLivesVal == 1 && !is_correct) {
                  return "question_audio_incorrect_last_attempt";
                }
              }`,
            params: ["is_correct", "componentsList"],
            isGlobal: true,
            forceGlobal: true,
        },
        {
            name: "updateStartInputSticker",
            type: "JAVASCRIPT",
            label: "update start input sticker",
            output: 'function updateSticker(index, "componentsList) {\n   return "";\n}',
            params: ["index", "componentsList"],
            isGlobal: true,
            forceGlobal: true,
        },
        {
            name: "updateEndInputSticker",
            type: "JAVASCRIPT",
            label: "update end input sticker",
            output: 'function updateSticker(index, componentsList) {\n   return "";\n}',
            params: ["index", "componentsList"],
            isGlobal: true,
            forceGlobal: true,
        },
    ],
    dragDrop: [
        {
            name: "updateStartSticker",
            type: "JAVASCRIPT",
            label: "update start sticker",
            output: "function updateSticker(\n  is_correct,\n  zoneIdx,\n  isStart,\n  isParent,\n  dropZones,\n  correctStatus,\n  itemClusterIdx,\n  isItemCluster,\n  pixiData\n) {\n  return '';\n}",
            params: [
                "is_correct",
                "zoneIdx",
                "itemClusterIdx",
                "isStart",
                "isItemCluster",
                "isParent",
                "dropZones",
                "correctStatus",
            ],
            isGlobal: true,
            forceGlobal: true,
        },
        {
            name: "updateEndSticker",
            type: "JAVASCRIPT",
            label: "update End sticker",
            output: "function updateSticker(\n  is_correct,\n  zoneIdx,\n  isStart,\n  isParent,\n  dropZones,\n  correctStatus,\n  itemClusterIdx,\n  isItemCluster,\n  pixiData\n) {\n  return '';\n}",
            params: [
                "is_correct",
                "zoneIdx",
                "itemClusterIdx",
                "isStart",
                "isItemCluster",
                "isParent",
                "dropZones",
                "correctStatus",
            ],
            isGlobal: true,
            forceGlobal: true,
        },
    ],
    TapAndSelect: [
        {
            name: "updateStartSticker",
            type: "JAVASCRIPT",
            label: "update start sticker",
            output: `function updateStartAudio(is_correct, componentsList) {
                return 'question_audio_checking';
              }`,
            params: ["is_correct", "componentsList"],
            isGlobal: true,
            forceGlobal: true,
        },
        {
            name: "updateEndSticker",
            type: "JAVASCRIPT",
            label: "update End sticker",
            output: `function updateEndAudio(is_correct, componentsList, simple_inputs) {
                if(is_correct) return 'question_audio_correct';
                const totalLives = pixiData.globalContext.parent_variables?.find((i) =>
                        i.name.includes("total_lives"),
                    );
                    const totalLivesVal = totalLives.default ?? totalLives.value;
                    if (totalLivesVal == 2 && !is_correct) {
                        return "question_audio_incorrect_try1";
                    }
                    if (totalLivesVal == 1 && !is_correct) {
                        return "question_audio_incorrect_try2";
                    }
                }`,
            params: ["is_correct", "componentsList"],
            isGlobal: true,
            forceGlobal: true,
        },
    ],
    MultiSelectInput: [
        {
            name: "updateStartSticker",
            type: "JAVASCRIPT",
            label: "update start sticker",
            output: `function updateStartAudio(is_correct, result) {
                return "question_audio_checking" 
                }`,
            params: ["is_correct", "result"],
            isGlobal: true,
            forceGlobal: true,
        },
        {
            name: "updateEndSticker",
            type: "JAVASCRIPT",
            label: "update End sticker",
            output: "function updateSticker(is_correct, result) {\n  return '';\n}",
            params: ["is_correct", "result"],
            isGlobal: true,
            forceGlobal: true,
        },
    ],
    SurferGame: [
        {
            name: "updateStartSticker",
            type: "JAVASCRIPT",
            label: "update start sticker",
            output: 'function updateSticker(game) {\n    return "";\n}',
            params: ["game"],
            isGlobal: true,
            forceGlobal: true,
        },
        {
            name: "updateEndSticker",
            type: "JAVASCRIPT",
            label: "update End sticker",
            output: 'function updateSticker(game) {\n    return "";\n}',
            params: ["game"],
            isGlobal: true,
            forceGlobal: true,
        },
    ],
    BalanceGame: [
        {
            name: "updateStartSticker",
            type: "JAVASCRIPT",
            label: "update start sticker",
            output: `function updateStartAudio(is_correct, sides) {
                return 'question_audio_checking';
              }`,
            params: ["is_correct", "sides"],
            isGlobal: true,
            forceGlobal: true,
        },
        {
            name: "updateEndSticker",
            type: "JAVASCRIPT",
            label: "update End sticker",
            output: `function updateEndFeedback(is_correct, sides) {
                if(is_correct) return 'question_audio_correct';
                const totalLives = pixiData.globalContext.parent_variables?.find((i) =>
                        i.name.includes("total_lives"),
                    );
                    const totalLivesVal = totalLives.default ?? totalLives.value;
                    if (totalLivesVal == 2 && !is_correct) {
                        return "question_audio_incorrect_try1";
                    }
                    if (totalLivesVal == 1 && !is_correct) {
                        return "question_audio_incorrect_try2";
                    }
                }`,
            params: ["is_correct", "sides"],
            isGlobal: true,
            forceGlobal: true,
        },
    ],
};
