import { v4 as uuid } from "uuid";

export const DEFAULT_DEADLINE_STORY = {
    __typename: "worksheet_block",
    id: 265835,
    data: {
        other: {
            job: { id: "", name: "", last_updated: null },
            audio: "",
            duration: 0,
        },
        children: [
            {
                id: "3922d671-8988-4641-9f12-2d30bc229869",
                data: {
                    other: { audio: "", duration: -1 },
                    children: [
                        {
                            data: {
                                name: "Yes",
                                button: {
                                    text: "Ok, thanks!",
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 0,
                            other: {
                                size: { width: 412, height: 84 },
                                position: { top: 648, left: 0 },
                            },
                            tmpId: "82a4c88f-e096-42db-9fde-64d4c1821b6f",
                        },
                    ],
                    v2_story: {
                        name: "1",
                        logic: {
                            compute_functions: [
                                {
                                    name: "getNextStory",
                                    label: "Get Next Story",
                                    output: "function getNextStory(history, inputs, global_context_variables) {\n  return 'next';\n}",
                                    params: [],
                                    isGlobal: true,
                                },
                            ],
                        },
                        is_skip: false,
                        background: {
                            loop: false,
                            type: "VIDEO",
                            value: "https://video.gumlet.io/6607c654258edd12779481f2/6722015b7a5365818691defb/main.mp4",
                            default: "",
                        },
                        bg_audio_list: [],
                        duration_type: null,
                        play_multiple_audio: true,
                    },
                },
                type: "v2_story",
                order: 1,
                tmpId: "532e9fc3-e642-49c8-8c3c-fba83ade2def",
            },
        ],
        v2_stories: {
            name: "Default Deadline story",
            banner: "",
            sub_title: "",
            dev_remarks: [{ type: "p", children: [{ text: "" }] }],
        },
        gen_audio_map: [[]],
    },
    type: "v2_stories",
    order: 1,
    backend: {
        slug: "default_deadline_story",
        compute_functions: [
            {
                name: "getNextBlock",
                label: "Get Next Block",
                output: "function getNextBlock(history, next, global_context_variables) {\n  return next;\n}",
                params: [],
                isGlobal: true,
            },
        ],
    },
};

export const DEFAULT_UNLOCK_TIME_STORY = {
    __typename: "worksheet_block",
    id: 265835,
    data: {
        other: {
            job: { id: "", name: "", last_updated: null },
            audio: "",
            duration: 0,
        },
        children: [
            {
                id: uuid(),
                data: {
                    other: { audio: "", duration: -1 },
                    children: [
                        {
                            data: {
                                name: "Yes",
                                button: {
                                    text: "Ok, thanks!",
                                    color: "#333333",
                                    action: { type: "internal", value: "" },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#FFDE49",
                                },
                            },
                            type: "button",
                            order: 0,
                            other: {
                                size: { width: 412, height: 84 },
                                position: { top: 648, left: 0 },
                            },
                            tmpId: uuid(),
                        },
                    ],
                    v2_story: {
                        name: "1",
                        logic: {
                            compute_functions: [
                                {
                                    name: "getNextStory",
                                    label: "Get Next Story",
                                    output: "function getNextStory(history, inputs, global_context_variables) {\n  return 'next';\n}",
                                    params: [],
                                    isGlobal: true,
                                },
                            ],
                        },
                        is_skip: false,
                        background: {
                            loop: false,
                            type: "VIDEO",
                            value: "https://video.gumlet.io/6607c654258edd12779481f2/6722015b7a5365818691defb/main.mp4",
                            default: "",
                        },
                        bg_audio_list: [],
                        duration_type: null,
                        play_multiple_audio: true,
                    },
                },
                type: "v2_story",
                order: 1,
                tmpId: uuid(),
            },
        ],
        v2_stories: {
            name: "Default Unlock time story",
            banner: "",
            sub_title: "",
            dev_remarks: [{ type: "p", children: [{ text: "" }] }],
        },
        gen_audio_map: [[]],
    },
    type: "v2_stories",
    order: 1,
    backend: {
        slug: "default_unlock_time_story",
        compute_functions: [
            {
                name: "getNextBlock",
                label: "Get Next Block",
                output: "function getNextBlock(history, next, global_context_variables) {\n  return next;\n}",
                params: [],
                isGlobal: true,
            },
        ],
    },
};

export const DEFAULT_PREREQUISITE_STORY = {
    __typename: "worksheet_block",
    id: 272775,
    data: {
        other: {
            job: {
                id: "",
                name: "",
                last_updated: null,
            },
            audio: "",
            duration: 0,
        },
        children: [
            {
                id: uuid(),
                data: {
                    other: {
                        audio: "",
                        duration: -1,
                    },
                    children: [
                        {
                            data: {
                                name: "btn",
                                button: {
                                    text: "Unlock now!",
                                    color: "#ffffff",
                                    action: {
                                        type: "internal",
                                        value: "",
                                    },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#e00e0e",
                                },
                            },
                            type: "button",
                            order: 0,
                            other: {
                                size: {
                                    width: 412,
                                    height: 84,
                                },
                                position: {
                                    top: 648,
                                    left: 0,
                                },
                            },
                            tmpId: "8824d639-c65e-4eed-a645-92020c57a75f",
                        },
                    ],
                    v2_story: {
                        name: "1",
                        logic: {
                            compute_functions: [
                                {
                                    name: "getNextStory",
                                    label: "Get Next Story",
                                    output: "function getNextStory(history, inputs, global_context_variables) {\n  return 'next';\n}",
                                    params: [],
                                    isGlobal: true,
                                },
                            ],
                        },
                        is_skip: false,
                        background: {
                            loop: false,
                            type: "VIDEO",
                            value: "https://video.gumlet.io/6606b823258edd12778f9c40/675faf3775669f4eda028f24/main.mp4",
                            default: "",
                        },
                        bg_audio_list: [],
                        duration_type: null,
                        play_multiple_audio: true,
                    },
                },
                type: "v2_story",
                order: 1,
                tmpId: uuid(),
            },
            {
                id: uuid(),
                data: {
                    other: {
                        audio: "",
                        duration: -1,
                    },
                    children: [
                        {
                            data: {
                                name: "btn",
                                button: {
                                    text: "Go to activities",
                                    color: "#ffffff",
                                    action: {
                                        type: "internal",
                                        value: "",
                                    },
                                    font_size: "18",
                                    font_weight: "600",
                                    background_color: "#3927ff",
                                },
                            },
                            type: "button",
                            order: 0,
                            other: {
                                size: {
                                    width: 412,
                                    height: 84,
                                },
                                position: {
                                    top: 648,
                                    left: 0,
                                },
                            },
                            tmpId: "8723156d-cd8e-43d5-b72c-07daed0654af",
                        },
                    ],
                    v2_story: {
                        name: "2",
                        logic: {
                            compute_functions: [
                                {
                                    name: "getNextStory",
                                    label: "Get Next Story",
                                    output: "function getNextStory(history, inputs, global_context_variables) {\n  return 'next';\n}",
                                    params: [],
                                    isGlobal: true,
                                },
                            ],
                        },
                        is_skip: false,
                        background: {
                            loop: false,
                            type: "VIDEO",
                            value: "https://video.gumlet.io/6606b823258edd12778f9c40/675faf4f75669f4eda028f84/main.mp4",
                            default: "",
                        },
                        bg_audio_list: [],
                        duration_type: null,
                        play_multiple_audio: true,
                    },
                },
                type: "v2_story",
                order: 2,
                tmpId: "fc0f0643-c359-4fd8-ad46-6ce4812c252b",
            },
        ],
        v2_stories: {
            name: "Default Prerequisite story",
            banner: "",
            sub_title: "",
            dev_remarks: [
                {
                    type: "p",
                    children: [
                        {
                            text: "",
                        },
                    ],
                },
            ],
        },
        gen_audio_map: [[], []],
    },
    type: "v2_stories",
    order: 7,
    backend: {
        slug: "default_prerequisite_story",
        compute_functions: [
            {
                name: "getNextBlock",
                label: "Get Next Block",
                output: "function getNextBlock(history, next, global_context_variables) {\n  return next;\n}",
                params: [],
                isGlobal: true,
            },
        ],
    },
};

export const DEFAULT_BUTTONS_LIST = [
    {
        text: "Next",
        type: "show_story",
        function:
            'function story(obj){\n  const story = obj.story;\n  if(story.story_id == "solution"||story.story_id == "name:solution"){\n    return \'Show solution\';\n  }\n  if(story.story_id == "correct"||story.story_id == "name:correct"){\n    return \'Next\';\n  }\n  if(story.story_id == "hint"||story.story_id == "name:hint"){\n  return \'Show hint\';\n}\n}',
        textType: "function",
    },
    {
        text: "Next",
        type: "next_question",
        function: "",
        textType: "normal",
    },
    {
        text: "Submit & check",
        type: "show_submit",
        function:
            'function submit(obj){\n  const {global_context_variables} = obj;\n  if(global_context_variables?.feedback_question == "true"){\n      return "Share feedback";\n  }\n  return "Submit & check";\n}',
        textType: "function",
    },
    {
        text: "Try Again",
        type: "show_try_again",
        function: "",
        textType: "normal",
    },
];
