interface KTDataItem {
    developer: string;
    discription?: string;
    ktSource: string;
    demoLink?: string;
    dashboardDemoLink?: string;
    QAPreviewLink?: string;
}

interface FeaturesType {
    [key: string]: string;
}

interface KTDataType {
    [key: string]: KTDataItem;
}

export function transformKTDataArray(ktDataArray: any[]): KTDataType {
    const ktDataObject: KTDataType = {};

    ktDataArray.forEach((item) => {
        const {
            feature,
            discription,
            developer,
            ktSource,
            demoLink,
            dashboardDemoLink,
            QAPreviewLink,
        } = item;

        ktDataObject[feature] = {
            developer,
            ...(discription && { discription }),
            ktSource,
            ...(demoLink && { demoLink }),
            ...(dashboardDemoLink && { dashboardDemoLink }),
            ...(QAPreviewLink && { QAPreviewLink }),
        };
    });

    return ktDataObject;
}

const ktDataArray = [
    {
        feature: "test",
        discription: "TEST",
        developer: "TEST",
        ktSource: "TEST",
        demoLink:
            "https://www.loom.com/embed/973a87fd0d7f49de89485bfe26796c91?sid=0b128f19-2785-4cfd-8fbd-27f690d0e41c",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "activity_type",
        discription: "Specify the activity type",
        developer: "Vinay Sammit",
        ktSource: "NA",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "worksheet_tags",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "worksheet_duration_text",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "pass_threshold",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "homepage_banner",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "worksheet_locked",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "unlock_all_levels",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "worksheet_banner",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "link_sharing_image",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "configure_question_button",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "prerequisite_worksheet_function",
        developer: "Sonakshi",
        ktSource: "Sonakshi",
        demoLink:
            "https://hw-kt-videos.s3.ap-south-1.amazonaws.com/prerequisite_worksheets.mp4",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "next_worksheet_function",
        developer: "Sammmit",
        ktSource: "NA",
        demoLink:
            "https://hw-kt-videos.s3.ap-south-1.amazonaws.com/next_worksheet.mp4",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "worksheet_stories",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "worksheet_deadline",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "worksheet_unlock_time",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "worksheet_played_multiple_times",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    // LEVEL
    {
        feature: "level_is_hidden",
        developer: "NA",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Level_type",
        developer: "Vinay Sammit",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Exit_Popup",
        developer: "Sammit",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Disable_resume",
        developer: "Sammit",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "student_leave",
        developer: "NA",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Passing_Badge_Count",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Level_tags",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Deadline_level",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "unlock_time_level",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "multiple_time_level",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    // CHUNK
    {
        feature: "Show_only_audio_list",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Chunk_Preview",
        developer: "Sammit",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    // BLOCK
    {
        feature: "block_show_only_audio_list",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Upload_Stories",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Set_block",
        developer: "Sammit",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "sub_blocks",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "audio_pool",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "sticker_pool",
        developer: "Sammit",
        ktSource: "NA",
        demoLink:
            "https://hw-kt-videos.s3.ap-south-1.amazonaws.com/Untitled+design.mp4",
        dashboardDemoLink:
            "https://dashboard-gql.vercel.app/worksheet/update/16267",
        QAPreviewLink:
            "https://qa.play.mathai.ai/home/worksheet/16267/269951?context=preview&progression_id=269951&progression_activity_id=ee9a1412-2355-4eb5-a569-154ca65243da&path_id=8c9e7f23-4091-4205-9f6f-539da3d1a1fa&variant_id=269959&block_id=269959&logs=true&showQuestion=true&noCache=true",
    },
    {
        feature: "block_type",
        developer: "Vinay",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "duration_link",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "reset_on_try_again",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "badge_count",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "No_badge_required",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "feedback_duration",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "duration-is_timed",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "global_context_variables",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "question_variables",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    // SHARE
    {
        feature: "Metadata_Tags",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Live_worksheet",
        developer: "Ishan",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    // GENERIC TABLE
    {
        feature: "partial_submission",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "filled_evaluation",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "evaluation_function",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "feedback_type",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "feedback_function",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "drawing_enabled",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "reorder_type",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Padding_Settings",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Gap_Settings",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "border_settings",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "Interaction_Limit_Handling",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "table_alignment",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "tappable_enabled",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "swipable_enabled",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "table_animations",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "table_effects",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "puzzle_mode",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "table_gamify",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "add_table",
        developer: "Lokesh",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
    {
        feature: "homepage_avatar_functions",
        developer: "Sammit",
        ktSource: "NA",
        demoLink: "",
        dashboardDemoLink: "https://dashboard.com/",
        QAPreviewLink: "https://qa-preview.com/",
    },
];

const KTData = transformKTDataArray(ktDataArray);

const features: FeaturesType = Object.keys(KTData).reduce((acc, feature) => {
    acc[feature] = feature;
    return acc;
}, {} as FeaturesType);

export function getKTData(feature: string): KTDataItem | undefined {
    const dataKey = features[feature];
    return dataKey
        ? KTData[dataKey]
        : {
              developer: "Not Found",
              ktSource: "Not Found",
              demoLink:
                  "https://hw-kt-videos.s3.ap-south-1.amazonaws.com/sample_video.mp4",
          };
}
