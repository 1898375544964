import { Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Radio } from "antd";
import { gql } from "graphql-request";
import { graphQLClient } from "src/modules/tab/components/PublishTabToProd";
import { getLastStringFromUrl } from "src/helpers";
import { useParams } from "react-router-dom";

const formatContextId = (id: string) => {
    return `personalizedLearning|worksheetCollection|${id}`;
};

const exampleJsonData = {
    icon: "",
    tittle: "",
    worksheetIds: [191829, 232322, 232323, 343434, 343434, 343566],
};

const extractOriginalId = (contextId: string) => {
    return contextId.split("|")[2] || "";
};

const FETCH_DATA_QUERY = gql`
    query getGenericData($id: String!) {
        other_generic_data(where: { context_id: { _eq: $id } }) {
            context_id
            data
            id
        }
    }
`;

const UPDATE_DATA_MUTATION = gql`
    mutation UpdateGenericData($object: other_generic_data_insert_input = {}) {
        insert_other_generic_data_one(
            object: $object
            on_conflict: {
                constraint: generic_data_context_id_key
                update_columns: [data, model]
            }
        ) {
            id
            context_id
            data
        }
    }
`;

const CREATE_DATA_MUTATION = gql`
    mutation CreateGenericData($object: other_generic_data_insert_input!) {
        insert_other_generic_data_one(object: $object) {
            id
            context_id
            data
        }
    }
`;

const UpdateSharePage = () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const [data, setData] = useState<any>();
    const [contextId, setContextId] = useState<any>("");
    const [displayId, setDisplayId] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [dataExists, setDataExists] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!id) return;
            try {
                const formattedContextId = formatContextId(id);
                const response: any = await graphQLClient.request(
                    FETCH_DATA_QUERY,
                    {
                        id: formattedContextId,
                    },
                );
                const existingData = response?.other_generic_data?.[0];

                if (existingData) {
                    setData(JSON.stringify(existingData.data ?? [], null, 2));
                    setContextId(existingData.context_id);
                    setDisplayId(extractOriginalId(existingData.context_id));
                    setDataExists(true);
                } else {
                    setContextId(formatContextId(id));
                    setDisplayId(id);
                    setDataExists(false);
                }
            } catch (error: any) {
                message.error(`Error in fetching data: ${error.message}`);
            }
        };
        if (id) {
            fetchData();
        }
    }, [id]);

    const handleSubmit = async () => {
        try {
            setLoading(true);

            // Validate JSON data
            let parsedData;
            try {
                parsedData = JSON.parse(data);
            } catch (error) {
                message.error("Invalid JSON format");
                setLoading(false);
                return;
            }

            const dataObject = {
                context_id: contextId,
                data: {
                    collectionSlug: extractOriginalId(contextId),
                    ...parsedData,
                },
                model: null,
            };

            if (dataExists) {
                // Update existing data
                await graphQLClient.request(UPDATE_DATA_MUTATION, {
                    object: dataObject,
                });
                message.success("Data updated successfully");
            } else {
                // Create new data
                await graphQLClient.request(CREATE_DATA_MUTATION, {
                    object: dataObject,
                });
                message.success("Data created successfully");
                setDataExists(true);
            }
        } catch (error: any) {
            message.error(
                `Error ${dataExists ? "updating" : "creating"} data: ${
                    error.message
                }`,
            );
        } finally {
            setLoading(false);
        }
    };
    const loadExampleData = () => {
        setData(JSON.stringify(exampleJsonData, null, 2));
    };
    return (
        <div
            style={{
                padding: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100dvh",
            }}
        >
            <Form
                layout="vertical"
                form={form}
                className="w-full border"
                style={{ width: "75%" }}
            >
                <h1 className="text-2xl font-bold mb-6">
                    📮 Worksheet's Collection Page Config
                </h1>

                <Form.Item label="Worksheet Collection Slug">
                    <Input
                        placeholder="Collection Slug"
                        disabled
                        value={displayId}
                    />
                </Form.Item>

                <Form.Item
                    label="Data in JSON Format"
                    help="Enter valid JSON data"
                    rules={[
                        {
                            validator: async (_, value) => {
                                try {
                                    if (value) JSON.parse(value);
                                    return Promise.resolve();
                                } catch (error) {
                                    return Promise.reject(
                                        "Please enter valid JSON",
                                    );
                                }
                            },
                        },
                    ]}
                >
                    <Input.TextArea
                        placeholder="Enter JSON data here"
                        rows={10}
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={loading}
                        disabled={loading}
                        style={{ marginRight: "10px" }}
                    >
                        {dataExists ? "Update" : "Create"}
                    </Button>
                    {!dataExists && (
                        <Button type="primary" onClick={loadExampleData}>
                            Load Example JSON Data
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};

export default UpdateSharePage;

export const SHARE_PAGE_ROUTE = [
    <Route
        key="worksheet_collection"
        path="/wc/:id"
        element={<UpdateSharePage />}
    />,
];
